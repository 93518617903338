import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle } from 'react'
import { FormGroup, Input, Label, Form, InputGroupText, Col, Row } from 'reactstrap'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import moment from 'moment'
import Select from 'react-select'
import { Alignment, Base64UploadAdapter, BlockQuote, Bold, ClassicEditor, Essentials, Font, Heading, HtmlEmbed, Image, ImageBlock, ImageUpload, Italic, Link, List, MediaEmbed, Mention, Paragraph, Strikethrough, Table, Underline, Undo } from 'ckeditor5'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import 'ckeditor5-premium-features/ckeditor5-premium-features.css'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import removeImg from '../../../../assets/images/ep-close.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import RequiredField from '../../../../components/RequiredField'


import getCategoryList from '../../../../api/category/getCategoryList'
import getMatchList from '../../../../api/category/getMatchList'

import { addSubCategory, getSubCategoryDetails, updateSubCategoory } from '../../../../actions/subCategory'
import { acceptFormat, modalMessageFunc, verifyLength } from '../../../../helpers/helper'

const AddSubCategory = forwardRef((props, ref) => {
  const { setSubmitDisableButton, adminPermission, Auth, isEdit, setIsCreate } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const [typeErr] = useState('')
  const [subCategoryId, setSubCategoryId] = useState('')
  // const [url, setUrl] = useState('')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [categoryType, setCategoryType] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')
  const [activeStatus, setActiveStatus] = useState('N')
  const [isTrending, setIsTrending] = useState(false)
  const [matchId, setMatchId] = useState()
  const [image, setImage] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [createType, setCreateType] = useState('')
  const [isAutoEvent, setIsAutoEvent] = useState(false)

  const [isOpenMatch, setIsOpenMatch] = useState(false)
  const [startMatch] = useState(0)
  const [limitMatch] = useState(10)

  const [errName, setErrName] = useState('')
  const [errImage, setErrImage] = useState('')
  const [errCategoryType, setErrCategoryType] = useState('')

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [close, setClose] = useState(false)

  const dispatch = useDispatch()
  const token = useSelector((state) => state?.auth?.token)
  const subCategoryDetails = useSelector((state) => state?.subCategory?.subCategoryDetails)
  const resStatus = useSelector((state) => state?.subCategory?.resStatus)
  const resMessage = useSelector((state) => state?.subCategory?.resMessage)
  const getUrlLink = useSelector(state => state?.url?.getUrl)

  const previousProps = useRef({
    resStatus,
    resMessage,
    subCategoryDetails
  })?.current
  
  const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const [modalMessage, setModalMessage] = useState(false)
  const submitDisable = subCategoryDetails && previousProps?.subCategoryDetails !== subCategoryDetails && subCategoryDetails?.sName === name && subCategoryDetails?.eType === categoryType

  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['getMatchList', startMatch, limitMatch],
    queryFn: ({ pageParam = startMatch }) => getMatchList(pageParam, limitMatch),
    getNextPageParam: (lastPage) => {
      const { total, results } = lastPage?.data?.data;
      const nextPage = results?.length ? results?.length : null;
      return nextPage && results.length < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  useEffect(() => {
    if (id) {
      dispatch(getSubCategoryDetails(id, token))
      setSubCategoryId(id)
      setLoading(true)
      setIsCreate(false)
    }
  }, [])
  useEffect(() => {
    setSubmitDisableButton(submitDisable)
  }, [])
  useEffect(() => {
    if (getUrlLink) {
      // setUrl(getUrlLink)
      localStorage.setItem("url", getUrlLink)
    }
  }, [getUrlLink])
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus) {
          navigate(`/category-management/subcategory${page?.SubCategoryManagement || ''}`, { state: { message: resMessage } })
        } else {
          setModalMessage(true)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])
  useEffect(() => {
    if (previousProps?.subCategoryDetails !== subCategoryDetails) {
      if (subCategoryDetails) {
        const url = localStorage.getItem("url")
        setName(subCategoryDetails?.sName)
        setDescription(subCategoryDetails?.sDescription)
        setCategoryType(subCategoryDetails?.eCategoryType)
        if (subCategoryDetails?.eCategoryType === 'C') {
          setIsOpenMatch(true)
          setMatchId(subCategoryDetails?.iMatchId)
        }
        setCreatedAt(subCategoryDetails?.dCreatedAt)
        setUpdatedAt(subCategoryDetails?.dUpdatedAt)
        setCreateType(subCategoryDetails?.eCreateType === 'A' ? 'Auto' : 'Manual')
        setImage(subCategoryDetails?.sImage ? subCategoryDetails?.sImage?.includes('https://') ? subCategoryDetails?.sImage : url + subCategoryDetails?.sImage : '')
        setActiveStatus(subCategoryDetails?.eStatus)
        setCategoryId(subCategoryDetails?.iCategoryId)
        setIsTrending(subCategoryDetails?.bIsTrending)
        setIsAutoEvent(subCategoryDetails?.bShouldCreateAutoEvent)
        setLoading(false)
      }
    }
    return () => {
      previousProps.subCategoryDetails = subCategoryDetails
    }
  }, [subCategoryDetails])

  function handleChange (event, field) {
    switch (field) {
      case 'Name':
        if (!event?.target?.value) {
          setErrName('Required field')
        } else if (event?.target?.value.trimStart().length === 0) {
          setErrName('No Initial Space Allowed')
        } else {
          setErrName('')
        }
        setName(event?.target?.value?.trimStart())
        break
      case 'Description':
        setDescription(event?.target?.value)
        break
      case 'categoryType':
        if (!event?.target?.value) {
          setErrCategoryType('Required field')
        } else {
          setErrCategoryType('')
        }
        if (event?.target?.value === "C") {
          setIsOpenMatch(true)
        } else {
          setIsOpenMatch(false)
        }
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target.options[selectedIndex];
        const selectedKey = selectedOption.getAttribute('data-key');
        setCategoryId(selectedKey)
        setCategoryType(event?.target?.value)
        break
      case 'isTrending':
        setIsTrending(event?.target?.checked)
        break
      case 'isAutoEvent':
        setIsAutoEvent(event?.target?.checked)
        break
      case 'Image':
        if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
          setImage({ imageURL: URL?.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'RemoveImage':
        setImage('')
        break
      case 'Status':
        setActiveStatus(event?.target?.value)
        break
      default:
        break
    }
  }
  function onSubmit (e) {
    if (verifyLength(name, 1) && !errName && !errImage && !typeErr) {
      if (!subCategoryId) {
        const subCategoryData = {
          name,
          description,
          categoryType,
          image,
          activeStatus,
          token,
          categoryId,
          matchId,
          isTrending,
          isAutoEvent
        }
        if (categoryType === "C") {
          subCategoryData.matchId = matchId
        }
        dispatch(addSubCategory(subCategoryData))
        setLoading(true)
      } else {
        const subCategoryData = {
          name,
          description,
          categoryType,
          image,
          activeStatus,
          token,
          categoryId,
          subCategoryId,
          matchId,
          isTrending,
          isAutoEvent
        }
        if (categoryType === "C") {
          subCategoryData.matchId = matchId
        }
        dispatch(updateSubCategoory(subCategoryData))
        setLoading(true)
      }
    } else {
      if (!verifyLength(name, 1)) {
        setErrName('Required field')
      }
      if (!verifyLength(categoryType, 1)) {
        setErrCategoryType('Required field')
      }
      // if(!verifyLength(description,1)){
      //   setErrDescription('Required field')
      // }
    }
  }

  // to handle image error occurred during add time
  function onImageError (ev) {
    ev.target.src = documentPlaceholder
  }
  function onEditorChange (evt, editor) {
    // if (verifyLength(editor?.getData(), 1)) {
    //   setErrDescription('')
    // } else {
    //   setErrDescription('Required field')
    // }
    setDescription(editor?.getData())
  }
  useImperativeHandle(ref, () => ({
    onSubmit
  }))
  const options = data?.map(match => ({
    value: match._id,
    label: `${match?.sShortTitle} - ${match?.oSeries?.sTitle}`,
  })) || [];
  return (
    <main className='main-content'>
      {loading && <Loading />}
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <section className='common-form-block'>
        <Form>
          <Row>
            <Col md={12} xl={12}>
              <FormGroup>
                <div className="theme-image text-center">
                  <div className="d-flex theme-photo">
                    <div className={image ? 'theme-img' : 'theme-img-default new-theme'}>
                      <img alt="PlayerImage" className={image ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={image?.imageURL ? image?.imageURL : image ? image : documentPlaceholder} />
                      {image && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY === 'W')) &&
                        <div className='remove-img-label'>
                          <img alt="removeImage" onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} />
                        </div>}
                      {!image && ((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY === 'W')) &&
                        (
                          <div className="custom-file">
                            <Input
                              accept={acceptFormat()}
                              id="exampleCustomFileBrowser"
                              name="customFile"
                              onChange={event => handleChange(event, 'Image')}
                              type="file"
                              className="custom-file-input"
                            />
                            <span className="upload-text">Upload Image</span>
                          </div>
                        )}
                    </div>
                  </div>
                  <p className="error-text">{errImage}</p>
                </div>
              </FormGroup>
            </Col>
            {/* <h5 className='mx-4 required-field'>
              Note : Please upload images with dimensions of 327 × 56 pixels.
            </h5> */}
          </Row>
          <Row>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='name'>
                  Sub Category Name
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={errName ? 'league-placeholder-error' : 'input-box-setting'}
                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                  id='name'
                  onChange={(event) => handleChange(event, 'Name')}
                  placeholder='Enter Category Name'
                  type='text'
                  value={name}
                />
                <p className='error-text'>{errName}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Category Type
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={errCategoryType ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                  name="category"
                  onChange={event => handleChange(event, 'categoryType')}
                  type="select"
                  value={categoryType}
                >
                  <option value=''>Select Category</option>
                  {categoryList?.map((category) => {
                    return <option value={category?.eCategoryType} data-key={category?._id}>{category?.sName}</option>
                  })}
                </Input>
                <p className="error-text">{errCategoryType}</p>
              </FormGroup>
            </Col>
          </Row>

          <Row className='version-type'>
            <Col md={12} xl={12}>
              <FormGroup className="form-group">
                <Label className='edit-label-setting' for="status">
                  Description
                  {' '}
                  {/* <RequiredField /> */}
                </Label>
                <div className={''}>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      placeholder: 'Enter Description',
                      toolbar: {
                        items: [
                          'heading', '|', 'fontSize', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor', '|',
                          'imageUpload', '|',
                          'bold', 'italic', 'underline', 'strikethrough', '|',
                          'alignment', '|', 'numberedList', 'bulletedList', '|',
                          'link', 'blockQuote',
                          'insertTable', 'mediaEmbed', '|', 'undo', 'redo', '|', 'htmlembed'
                        ]
                      },
                      plugins: [
                        Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, Font, Underline, Strikethrough, Alignment, List, Image, Link, BlockQuote, Table, MediaEmbed, ImageUpload, ImageBlock, HtmlEmbed, Base64UploadAdapter
                      ]
                    }}
                    data={description ? description : ""}
                    disabled={adminPermission?.OT_CATEGORY === 'R'}
                    onChange={onEditorChange}
                    onInit={(editor) => {
                      editor?.ui?.getEditableElement()?.parentElement?.insertBefore(editor?.ui?.view?.toolbar?.element, editor?.ui?.getEditableElement())
                    }}
                  />
                </div>
                {/* <p className="error-text">{errDescription}</p> */}
              </FormGroup>
            </Col>
          </Row>
          {isEdit && <>
            <Row className='mt-3'>
              <Col md={12} xl={6} >
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='name'>Created Date</Label>
                  <InputGroupText className='input-box-setting'>
                    {moment(createdAt)?.format('DD/MM/YYYY hh:mm A')}
                  </InputGroupText>
                </FormGroup>
              </Col>

              <Col className='version-date' md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='name'>Last Updated Date</Label>
                  <InputGroupText className='input-box-setting'>
                    {moment(updatedAt)?.format('DD/MM/YYYY hh:mm A')}
                  </InputGroupText>
                </FormGroup>
              </Col>
            </Row>
          </>}
          <Row className='p-3 mt-2'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group' switch>
                <div className="d-flex inline-input mt-2">
                  <div className='custom-radio custom-control'>
                    <Label for="ActiveOffer">Is Trending</Label>
                    <Input
                      checked={isTrending}
                      disabled={adminPermission?.OT_CATEGORY === 'R'}
                      id={'isTrending'}
                      name='isTrending'
                      onChange={event => handleChange(event, 'isTrending')}
                      type='switch'
                      className='custom-control-input me-2'
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            {isOpenMatch &&
              <Col md={12} xl={6}>
                <Select
                  options={options}
                  isDisabled={adminPermission?.OT_CATEGORY === 'R'}
                  onMenuScrollToBottom={() => {
                    if (hasNextPage && !isFetching) {
                      fetchNextPage()
                    }
                  }}
                  onChange={(selectedOption) => setMatchId(selectedOption?.value)}
                  placeholder='Select Match'
                  defaultValue={options.find(option => option.value === matchId)}
                />
              </Col>
            }

          </Row>
          <Row>
            <Col className='version-date' md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='name'>Create Type</Label>
                <InputGroupText className='input-box-setting'>{createType}</InputGroupText>
              </FormGroup>
            </Col>
            {(subCategoryDetails?.eCreateType === 'A') && (
              <Col md={12} xl={6} className='mt-4'>
                <FormGroup className='form-group' switch>
                  <div className="d-flex inline-input mt-2">
                    <div className='custom-radio custom-control'>
                      <Label for="ActiveOffer">Auto Create Event</Label>
                      <Input
                        checked={isAutoEvent}
                        disabled={adminPermission?.OT_CATEGORY === 'R'}
                        id={'isAutoEvent'}
                        name='isAutoEvent'
                        onChange={event => handleChange(event, 'isAutoEvent')}
                        type='switch'
                        className='custom-control-input me-2'
                      />
                    </div>
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row className='p-3 mt-2' >
            <div className='radio-button-div'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting lable-league' for="ActiveOffer">Status</Label>
                  <div className="d-flex inline-input mt-2 ">
                    <div className="custom-radio custom-control">
                      <Input
                        checked={activeStatus === 'Y'}
                        disabled={adminPermission?.OT_CATEGORY === 'R'}
                        id="contentRadio1"
                        name="contentRadio"
                        onChange={event => handleChange(event, 'Status')}
                        type="radio"
                        defaultValue="Y"
                        className='custom-control-input me-2'
                      />
                      <Label for="contentRadio1">Active</Label>
                    </div>
                    <div className="custom-radio custom-control">
                      <Input
                        checked={activeStatus !== 'Y'}
                        disabled={adminPermission?.OT_CATEGORY === 'R'}
                        id="contentRadio2"
                        name="contentRadio"
                        onChange={event => handleChange(event, 'Status')}
                        type="radio"
                        value="N"
                        className='custom-control-input me-2'
                      />
                      <Label for="contentRadio2">In Active</Label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </div>
          </Row>
        </Form>
      </section>
    </main>
  )
})

AddSubCategory.propTypes = {
  //   match: PropTypes.object,
  //   history: PropTypes.object,
  //   Auth: PropTypes.string,
  //   adminPermission: PropTypes.object,
  //   setSubmitDisableButton: PropTypes.func
}

AddSubCategory.displayName = AddSubCategory
export default connect(null, null, null, { forwardRef: true })(AddSubCategory)
