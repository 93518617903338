import React, { useEffect } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import infoIcon from '../../../assets/images/info-icon.svg'
import refreshIcon from '../../../assets/images/refresh-icon-1.svg'

import DataNotFound from '../../../components/DataNotFound'
import Info from '../../../helpers/info'
import { CategoryLabels, dateFormate, fixDigit } from '../../../helpers/helper'
import { updatePlayedReport } from '../../../actions/reports'

function PlayReport (props) {
  const { dateWiseReports, isOpen, permission, played, token, userType, setLoading, previousProps, setPlayed } = props;
  const dispatch = useDispatch();
  const updatedPlayedData = useSelector(state => state?.reports?.updatedPlayedData);

  useEffect(() => {
    if (updatedPlayedData) {
      if (previousProps?.updatedPlayedData !== updatedPlayedData) {
        const categoryIndex = played?.findIndex((playedData) => playedData?.eCategory === updatedPlayedData?.eCategory);
        const newArray = [...played];
        newArray[categoryIndex] = {
          ...newArray[categoryIndex],
          nTotalCash: updatedPlayedData?.nTotalCash,
          nTotalBonus: updatedPlayedData?.nTotalBonus,
          nTodayCash: updatedPlayedData?.nTodayCash,
          nTodayBonus: updatedPlayedData?.nTodayBonus,
          nYesterCash: updatedPlayedData?.nYesterCash,
          nYesterBonus: updatedPlayedData?.nYesterBonus,
          nWeekCash: updatedPlayedData?.nWeekCash,
          nWeekBonus: updatedPlayedData?.nWeekBonus,
          nMonthCash: updatedPlayedData?.nMonthCash,
          nMonthBonus: updatedPlayedData?.nMonthBonus,
          nYearCash: updatedPlayedData?.nYearCash,
          nYearBonus: updatedPlayedData?.nYearBonus,
          dUpdatedAt: updatedPlayedData?.dUpdatedAt
        };
        setPlayed(newArray);
      }
    }
    return () => {
      previousProps.updatedPlayedData = updatedPlayedData;
    };
  }, [updatedPlayedData]);

  function updatePlayedFunc (id, key, sportsType) {
    if (token) {
      const updatePlayedData = {
        id, key, sportsType, userType, token
      };
      dispatch(updatePlayedReport(updatePlayedData));
      setLoading(true);
    }
  }

  return (
    <>
      {isOpen === 'PLAY_REPORT' && dateWiseReports?.length !== 0 && (
      <section className='user-section'>
        <div className='table-represent-two'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr align='center'>
                  <th rowSpan='2'>
                    Category
                    <img className='custom-info' id='PLAY' src={infoIcon} alt='custom-info' />
                    <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='PLAY'>
                      <p>{Info?.played}</p>
                    </UncontrolledTooltip>
                  </th>
                  <th className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Total (Cash)' : 'Total'}</th>
                  <th rowSpan='2'>Last Update</th>
                </tr>
                  {userType !== 'B' && (
                    <tr align='center'>
                      <th className='report-th-1 text-center'>Cash</th>
                      <th className='report-th-2 text-center'>Bonus</th>
                    </tr>
                  )}
              </thead>
              <tbody>
                {dateWiseReports?.aPlayed && dateWiseReports?.aPlayed?.map((data, index) => (
                  <tr key={index} align='center'>
                    <td><b>{data?.eCategory === 'Y' ? CategoryLabels?.YT : data?.eCategory === 'C' ? CategoryLabels?.C : data?.eCategory === 'S' ? CategoryLabels?.S : data?.eCategory === 'CR' ? CategoryLabels?.CR : data?.eCategory === 'GT' ? CategoryLabels?.GT : data?.eCategory === 'IPO' ? CategoryLabels?.IPO : CategoryLabels?.N}</b></td>
                    <td>{fixDigit(data?.nTotalCash)}</td>
                    {userType !== 'B' && <td>{fixDigit(data?.nTotalBonus)}</td>}
                    <td>{dateFormate(data?.dUpdatedAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      )}
      {isOpen === 'PLAY_REPORT' && dateWiseReports?.length === 0
        ? (played && played?.length > 0)
            ? (
              <section className='user-section'>
                <div className='table-represent-two'>
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr align='center'>
                          <th align='center' rowSpan='2'>Category</th>
                          <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Total (Cash)' : 'Total'} </th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Today (Cash)' : 'Today'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Yesterday (Cash)' : 'Yesterday'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Week (Cash)' : 'Week'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Month (Cash)' : 'Month'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Year (Cash)' : 'Year'}</th>
                          <th align='center' rowSpan='2'>Last Update</th>
                          <th align='center' rowSpan='2'>
                            <img className='custom-info' id='PLAY' src={infoIcon} alt='custom-info' />
                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='PLAY'>
                              <p>{Info?.played}</p>
                            </UncontrolledTooltip>
                          </th>
                        </tr>
                      {userType !== 'B' &&
                        <tr align='center'>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                        </tr>
                      }
                      </thead>
                      <tbody>
                        {played?.map((data, index) => (
                          <tr key={index} align='center'>
                            <td><b>{data?.eCategory === 'Y' ? CategoryLabels?.YT : data?.eCategory === 'C' ? CategoryLabels?.C : data?.eCategory === 'S' ? CategoryLabels?.S : data?.eCategory === 'CR' ? CategoryLabels?.CR : data?.eCategory === 'GT' ? CategoryLabels?.GT : data?.eCategory === 'IPO' ? CategoryLabels?.IPO : CategoryLabels?.N}</b></td>
                            <td>{fixDigit(data?.nTotalCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nTotalBonus)}</td>}
                            <td>{fixDigit(data?.nTodayCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nTodayBonus)}</td>}
                            <td>{fixDigit(data?.nYesterCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nYesterBonus)}</td>}
                            <td>{fixDigit(data?.nWeekCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nWeekBonus)}</td>}
                            <td>{fixDigit(data?.nMonthCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nMonthBonus)}</td>}
                            <td>{fixDigit(data?.nYearCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nYearBonus)}</td>}
                            <td>{dateFormate(data?.dUpdatedAt)}</td>
                            <td>
                              {permission && (
                              <Button color='link' onClick={() => updatePlayedFunc(data?._id, 'PL', data?.eCategory)}>
                                <img alt='Participants' height='20px' src={refreshIcon} width='20px'/>
                              </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              )
            : <DataNotFound message="Data" obj=""/>
        : ''}
    </>
  );
}

PlayReport.propTypes = {
  dateWiseReports: PropTypes?.string,
  isOpen: PropTypes?.bool,
  sports: PropTypes?.array,
  permission: PropTypes?.string,
  played: PropTypes?.array,
  updatePlayedFunc: PropTypes?.func,
  userType: PropTypes?.string,
  token: PropTypes?.string,
  setLoading: PropTypes?.bool,
  previousProps: PropTypes?.object,
  setPlayed: PropTypes?.func
}
export default PlayReport
