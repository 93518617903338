import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, UncontrolledDropdown } from "reactstrap"
// import { useMyContext } from "../context/context"

import Logo from '../assets/images/logo.png'
import Sidebar from '../assets/images/dashboard.svg'
import ContentManagement from '../assets/images/content.svg'
import upArrow from '../assets/images/caret-top.svg'
import downArrow from '../assets/images/caret-bottom.svg'
import SubAdmin from '../assets/images/subAdmin-image.svg'
import Avatar from '../assets/images/avatar.svg'
import Settings from '../assets/images/setting-image.svg'
import Users from '../assets/images/user-image.svg'
import Transaction from '../assets/images/transaction.svg'
import Report from '../assets/images/report.svg'
import Chat from '../assets/images/chat.svg'

import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classNames from "classnames"
import * as authAction from '../actions/auth'
import { bindActionCreators } from "redux"

const NavbarComponent = () => {

  // const { state: { collapse: openCollapse }, dispatch: cDispatch } = useMyContext()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const openCollapse = true

  const [modalType, setModalType] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [dashboard] = useState(false)
  const [setting, setSetting] = useState(false)
  const [contentManagement, setContentManagement] = useState(false)
  const [subAdmin, setSubadmin] = useState(false)
  const [category, setCategory] = useState(false)
  const [user, setUser] = useState(false)
  const [event, setEvent] = useState(false)
  const [profileLevel, setProfileLevel] = useState(false)
  const [transaction, setTransaction] = useState(false)

  const { logoutAction } = bindActionCreators(authAction, dispatch)
  const token = useSelector(state => state?.auth?.token)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminDetails = useSelector(state => state?.auth?.adminData)
  function toggle1 (type, fadeClose) {
    setModalType(type)
    setIsOpen(true)
  }
  function closeModal () {
    setModalType('')
    if (openCollapse) {
      setIsOpen(true)
    } else if (location?.pathname?.includes('/settings')) {
      setSetting(true)
    } else if (location?.pathname?.includes('/content-management')) {
      setContentManagement(true)
    } else {
      setIsOpen(false)
    }
  }
  function onLogout () {
    logoutAction(token).then((res) => {
      if (res?.status === 200) {
        localStorage?.removeItem('collapse')
        navigate("/auth/login")
      }
    })
  }
  const contentPath = location && (location?.pathname?.includes('/content-management'))
  const settingsPath = location && (location?.pathname?.includes('/settings'))


  return <Navbar className={`d-flex flex-column collapse-nav ${openCollapse && 'main-navbar'} p-0`} light>
    <div className={`menubar p-0 ${openCollapse ? 'collapse-menu' : ''}`}>
      <NavbarToggler tag={Button} />
      <Nav expand='lg' className={openCollapse ? 'navbar-nav collapse-nav-bar' : 'navbar-nav align-items-center'}>
        <div className={openCollapse ? 'fantasyLogo justify-content-center' : 'fansportizLogo justify-content-center'}>
            <img src={Logo} alt="opinionlogo" />
        </div>
        <div className={openCollapse ? 'opendropDown-list' : 'dropDown-list'}>
          {/* {adminPermission?.DASHBOARD === 'R' || Auth && Auth === 'SUPER' && <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse' : 'custom-dropdown'} isOpen={isOpen && modalType === 'Dashboard'} onMouseOver={() => !openCollapse && toggle1('Dashboard')} onMouseOut={() => closeModal()} > */}
          <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse' : 'custom-dropdown'} isOpen={isOpen && modalType === 'Dashboard'} onMouseOver={() => !openCollapse && toggle1('Dashboard')} onMouseOut={() => closeModal()} >
            <DropdownToggle nav caret id='dashboard' className={openCollapse && dashboard ? 'd-flex active-nav-link' : ''} onClick={() => navigate('/dashboard')}>
              <img src={Sidebar} alt='Dashboard' />
              {openCollapse && (
                <>
                  <h4 className='w-100'>DASHBOARD</h4>
                </>
              )}
            </DropdownToggle>
            <DropdownMenu className='p-0'>
              <div className='dropdown-inner'>
                <DropdownItem onClick={() => navigate('/dashboard')}>Dashboard</DropdownItem>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>

          {(adminPermission?.VERSION === 'N' && adminPermission?.COMPLAINT === 'N' && adminPermission?.LEADERSHIP_BOARD === 'N' && adminPermission?.EMAIL_TEMPLATES === 'N' && adminPermission?.POPUP_ADS === 'N' && adminPermission?.PAYOUT_OPTION === 'N' && adminPermission?.REPORT === 'N' && adminPermission?.OFFER === 'N' && adminPermission?.CMS === 'N' && adminPermission?.PROMO === 'N' && adminPermission?.BANNER === 'N' && adminPermission?.SETTING === 'N' && adminPermission?.PAYMENT_OPTION === 'N' && adminPermission?.RULE === 'N' && adminPermission?.NOTIFICATION === 'N' && adminPermission?.SPORT === 'N')
            ? ''
            : (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'Settings')} onMouseOver={() => !openCollapse && toggle1('Settings', true)} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='setting' className={` ${classNames({ active: settingsPath })}  ${setting ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setSetting(!setting)}>
                    <img src={Settings} alt='settings' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>SETTINGS</h4>
                        <img src={setting ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {setting && !(adminPermission?.VERSION === 'N' && adminPermission.COMPLAINT === 'N' && adminPermission.LEADERSHIP_BOARD === 'N' && adminPermission.EMAIL_TEMPLATES === 'N' && adminPermission.POPUP_ADS === 'N' && adminPermission.PAYOUT_OPTION === 'N' && adminPermission.REPORT === 'N' && adminPermission.OFFER === 'N' && adminPermission.CMS === 'N' && adminPermission.PROMO === 'N' && adminPermission.BANNER === 'N' && adminPermission.SETTING === 'N' && adminPermission.PAYMENT_OPTION === 'N' && adminPermission.RULE === 'N' && adminPermission.SCORE_POINT === 'N' && adminPermission.NOTIFICATION === 'N' && adminPermission.SPORT === 'N') && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/settings/setting-management"> Settings</Button>
                        <Button tag={Link} className={classNames({ active: location && ((location.pathname === '/settings/common-rules') || location.pathname.includes('/settings/add-common-rule') || location.pathname.includes('/settings/common-rules-details')) })} to="/settings/common-rules"> Common Rules</Button>
                        <Button tag={Link} to="/settings/versions"> Versions/Maintenance</Button>
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )}
          {(adminPermission?.VERSION === 'N' && adminPermission?.COMPLAINT === 'N' && adminPermission?.LEADERSHIP_BOARD === 'N' && adminPermission?.EMAIL_TEMPLATES === 'N' && adminPermission?.POPUP_ADS === 'N' && adminPermission?.PUSHNOTIFICATION === 'N' && adminPermission?.PAYOUT_OPTION === 'N' && adminPermission?.REPORT === 'N' && adminPermission?.OFFER === 'N' && adminPermission?.CMS === 'N' && adminPermission?.PROMO === 'N' && adminPermission?.BANNER === 'N' && adminPermission?.SETTING === 'N' && adminPermission?.PAYMENT_OPTION === 'N' && adminPermission?.RULE === 'N' && adminPermission?.NOTIFICATION === 'N' && adminPermission?.SPORT === 'N' && adminPermission?.MERCHANDISE === 'N')
            ? ''
            : (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'ContentManagement')} onMouseOver={() => !openCollapse && toggle1('ContentManagement', true)} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='contentManagement' className={` ${classNames({ active: contentPath })}  ${contentManagement ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setContentManagement(!contentManagement)}>
                    <img src={ContentManagement} alt='settings' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>CONTENTS</h4>
                        <img src={contentManagement ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {contentManagement && !(adminPermission?.VERSION === 'N' && adminPermission.COMPLAINT === 'N' && adminPermission.LEADERSHIP_BOARD === 'N' && adminPermission.EMAIL_TEMPLATES === 'N' && adminPermission.POPUP_ADS === 'N' && adminPermission.PAYOUT_OPTION === 'N' && adminPermission.REPORT === 'N' && adminPermission.OFFER === 'N' && adminPermission.CMS === 'N' && adminPermission.PROMO === 'N' && adminPermission.BANNER === 'N' && adminPermission.SETTING === 'N' && adminPermission.PAYMENT_OPTION === 'N' && adminPermission.RULE === 'N' && adminPermission.SCORE_POINT === 'N' && adminPermission.NOTIFICATION === 'N' && adminPermission.SPORT === 'N' && adminPermission?.MERCHANDISE === 'N') && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/content-management/content"> Content</Button>
                        <Button tag={Link} to="/content-management/push-notification"> Push Notifications</Button>
                        <Button tag={Link} to="/content-management/slider-management"> Sliders</Button>
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )}

          {((Auth && Auth === 'SUPER') || (adminPermission?.SUBADMIN !== 'N')) && (
            <Fragment>
              <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'category')} onMouseOver={() => !openCollapse && toggle1('category')} onMouseOut={() => closeModal()}>
                <DropdownToggle nav caret id='category' className={`${classNames({ active: location && (location?.pathname?.includes('/category-management') || location?.pathname?.includes('/category')) })} ${category ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setCategory(!category)}>
                  <img src={SubAdmin} alt='category' />
                  {openCollapse && (
                    <>
                      <h4 className='w-100'>CATEGORY MANAGEMENT</h4>
                      <img src={category ? upArrow : downArrow} alt="" className='caretIcon' />
                    </>
                  )}
                </DropdownToggle>
                {category && (
                  <>
                    <div className='expand-menu'>
                      <Button tag={Link} className={classNames({ active: location && (location?.pathname === '/category-management/categories' || location?.pathname?.includes('/category-management/update-category/')) })} to='/category-management/category'>Category</Button>
                      <Button tag={Link} className={classNames({ active: location && (location?.pathname === '/category-management/subcategory' || location?.pathname === '/category-management/add-subcategory' || location?.pathname?.includes('/category-management/edit-subcategory/')) })} to='/category-management/subcategory'>Sub Category</Button>
                    </div>
                  </>
                )}
              </UncontrolledDropdown>
            </Fragment>
          )}
          {(adminPermission?.USERS === 'N' && adminPermission?.KYC === 'N' && adminPermission?.PASSBOOK === 'N' && adminPermission?.WITHDRAW === 'N' && adminPermission?.DEPOSIT === 'N' && adminPermission?.PUSHNOTIFICATION === 'N' && adminPermission?.SYSTEM_USERS === 'N' && adminPermission?.TDS === 'N')
            ? ''
            : (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'Users')} onMouseOver={() => !openCollapse && toggle1('Users')} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='users' className={`${classNames({ active: location && location?.pathname.includes('/users/') })} ${user ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setUser(!user)}>
                    <img src={Users} alt='Users' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>USER MANAGEMENT</h4>
                        <img src={user ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {user && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/users/user-management"> Users</Button>
                        {/* <Button tag={Link} to="/users/dropped-users"> Dropped Users</Button> */}
                        {/* <Button tag={Link} to="/users/deleted-users"> Deleted Users</Button> */}
                        <Button tag={Link} to="/users/kyc-verification"> KYC Verification</Button>
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )
          }

          {(adminPermission?.USERS === 'N' && adminPermission?.KYC === 'N' && adminPermission?.PASSBOOK === 'N' && adminPermission?.WITHDRAW === 'N' && adminPermission?.DEPOSIT === 'N' && adminPermission?.PUSHNOTIFICATION === 'N' && adminPermission?.SYSTEM_USERS === 'N' && adminPermission?.TDS === 'N')
            ? ''
            : (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'Transaction')} onMouseOver={() => !openCollapse && toggle1('Transaction')} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='transaction' className={`${classNames({ active: location && location?.pathname.includes('/transaction') })} ${transaction ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setTransaction(!transaction)}>
                    <img src={Transaction} alt='Transaction' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>TRANSACTIONS</h4>
                        <img src={transaction ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {transaction && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/transaction/passbook"> Transactions</Button>
                        <Button tag={Link} to="/transaction/withdraw-management"> Withdrawals</Button>
                        <Button tag={Link} to="/transaction/deposit-management"> Deposits</Button>
                        <Button tag={Link} to="/transaction/tds-management"> TDS</Button>
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )
          }

          {((Auth && Auth === 'SUPER') || (adminPermission?.OT_EVENT !== 'N')) &&
            (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'Events')} onMouseOver={() => !openCollapse && toggle1('Events')} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='events' className={`${classNames({ active: location && location?.pathname.includes('/events/') })} ${user ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setEvent(!event)}>
                    <img src={Users} alt='Events' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>EVENT MANAGEMENT</h4>
                        <img src={user ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {event && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/events/event-management"> Events</Button>
                        <Button tag={Link} to="/events/event-template"> Event Template</Button>
                        {/* <Button tag={Link} to={`/orders/my-orders/${adminDetails?._id}`}> My Orders</Button>   */}
                        {/* <Button tag={Link} to="/event/orders">Orders</Button> */}
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )
          }
          {((Auth && Auth === 'SUPER') || (adminPermission?.PROFILE_LEVEL !== 'N')) &&
            (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'ProfileLevel')} onMouseOver={() => !openCollapse && toggle1('ProfileLevel')} onMouseOut={() => closeModal()}>
                  <DropdownToggle nav caret id='events' className={`${classNames({ active: location && location?.pathname.includes('/user-profile-level/') })} ${user ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setProfileLevel(!profileLevel)}>
                    <img src={Users} alt='Events' />
                    {openCollapse && (
                      <>
                        <h4 className='w-100'>USER XP MANAGEMENT</h4>
                        <img src={user ? upArrow : downArrow} alt="" className='caretIcon' />
                      </>
                    )}
                  </DropdownToggle>
                  {profileLevel && (
                    <>
                      <div className='expand-menu'>
                        <Button tag={Link} to="/user-profile-level"> User Profile Level</Button>
                      </div>
                    </>
                  )}
                </UncontrolledDropdown>
              </Fragment>
            )
          }

          {((Auth && Auth === 'SUPER') || (adminPermission?.SUBADMIN !== 'N')) && (
            <Fragment>
              <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'Subadmin')} onMouseOver={() => !openCollapse && toggle1('Subadmin')} onMouseOut={() => closeModal()}>
                <DropdownToggle nav caret id='subadmin' className={`${classNames({ active: location && (location?.pathname?.includes('/admin-management') || location?.pathname?.includes('/admin-logs')) })} ${subAdmin ? 'active-nav-link' : ''}`} onClick={() => openCollapse && setSubadmin(!subAdmin)}>
                  <img src={SubAdmin} alt='subadmin' />
                  {openCollapse && (
                    <>
                      <h4 className='w-100'>ADMIN MANAGEMENT</h4>
                      <img src={subAdmin ? upArrow : downArrow} alt="" className='caretIcon' />
                    </>
                  )}
                </DropdownToggle>
                {subAdmin && (
                  <>
                    <div className='expand-menu'>
                      <Button tag={Link} className={classNames({ active: location && (location?.pathname === '/admin-management/roles' || location?.pathname === '/admin-management/add-role' || location?.pathname?.includes('/admin-management/update-role/')) })} to='/admin-management/roles'>Roles</Button>
                      <Button tag={Link} className={classNames({ active: location && (location?.pathname === '/admin-management' || location?.pathname === '/admin-management/add-admin' || location?.pathname?.includes('/admin-management/edit-admin/')) })} to='/admin-management'>Admin Management</Button>
                      <Button tag={Link} className={classNames({ active: location && (location?.pathname?.includes('/admin-logs')) })} to='/admin-logs'>Admin Logs</Button>
                    </div>
                  </>
                )}
              </UncontrolledDropdown>
            </Fragment>
          )}
          {(adminPermission?.REPORT === 'W' || (Auth && Auth === 'SUPER')) &&
            (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'report')} onMouseOver={() => !openCollapse && toggle1('report')} onMouseOut={() => closeModal()}>
                  <div className={`reportNavbar ${classNames({ active: location && (location?.pathname === '/reports/all-reports') }) && 'active-nav-link'} ${openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'}`} onClick={() => navigate('/reports/all-reports')}>
                    <img src={Report} alt='Report' />
                    {openCollapse && (
                      <>
                        <h3 className='w-100'>REPORT</h3>
                      </>
                    )}
                  </div>
                </UncontrolledDropdown>
              </Fragment>
            )
          }
          {(adminPermission?.SUPPORT_CHAT === 'W' || (Auth && Auth === 'SUPER')) &&
            (
              <Fragment>
                <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'report')} onMouseOver={() => !openCollapse && toggle1('report')} onMouseOut={() => closeModal()}>
                  <div style={{ paddingLeft: '10px' }} className={`reportNavbar ${classNames({ active: location && (location?.pathname === '/chat-support') }) && 'active-nav-link'} ${openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'}`} onClick={() => navigate('/chat-support')}>
                    <img src={Chat} alt='Chat' style={{ width: '18px', height: '18px' }} />
                    {openCollapse && (
                      <>
                        <h3 className='w-100' style={{ paddingLeft: '6px' }}>CHAT SUPPORT</h3>
                      </>
                    )}
                  </div>
                </UncontrolledDropdown>
              </Fragment>
            )
          }
          {(adminPermission?.STREAK === 'W' || adminPermission?.STREAK === 'R' || (Auth && Auth === 'SUPER')) &&
            <Fragment>
              <UncontrolledDropdown nav inNavbar className={openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'} isOpen={isOpen && (modalType === 'report')} onMouseOver={() => !openCollapse && toggle1('report')} onMouseOut={() => closeModal()}>
                <div className={`reportNavbar ${classNames({ active: location && (location?.pathname === '/user-streak') }) && 'active-nav-link'} ${openCollapse ? 'custom-dropdown-collapse ' : 'custom-dropdown'}`} onClick={() => navigate('/user-streak')}>
                  <img src={Report} alt='Chat'/>
                  {openCollapse && (
                    <>
                      <h3 className='w-100'>STREAK</h3>
                    </>
                  )}
                </div>
              </UncontrolledDropdown>
            </Fragment>
          }
        </div>
        <div className={`w-100 text-left ${openCollapse ? 'bottom-menu-collapse' : 'bottom-menu'} `}>
          <UncontrolledDropdown nav inNavbar className='login-dropdown'>
            <DropdownToggle nav caret className='w-100 d-flex align-items-center login-toggle'>
              <img src={Avatar} alt='Profile Pic' className='mr-2 avatar-image' style={{ 'marginRight': '.5rem' }} />
              {openCollapse ? Auth === 'SUPER' ? adminDetails?.sName + '(Super)' : adminDetails?.sName + '(Sub)' : ''}
            </DropdownToggle>
            <DropdownMenu className='login-option'>
              <DropdownItem className='login-user'>
                {Auth === 'SUPER' ? adminDetails?.sName + '(Super)' : adminDetails?.sName + '(Sub)'}
              </DropdownItem>
              <DropdownItem onClick={onLogout} className='login'>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Nav>
    </div>
  </Navbar>
}
export default NavbarComponent