import React, { useState, Fragment, useEffect, useRef, forwardRef } from 'react'
import { Button, Form, FormGroup, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import calendarIcon from '../../../assets/images/calendar.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

function EventListHeader (props) {
	const { hidden,
		hasNextPage,
		isFetching,
		fetchNextPage,
		subCategoryData,
		recommendedList,
		list,
		search,
		handleSearch,
		startDate,
		endDate,
		eventFilter,
		eventDropdown,
		eventStatus,
		statusFilter,
		modalToggle,
		setIsManageColumn,
		categoryDropdown,
		categoryFilter,
		categoryList,
		setSubCategoryId,
		setDateRange,
		dateFlag,
		dateRange,
		onFiltering,
		selectedCategory
	} = props

	const [show, setShow] = useState(false)
	const previousProps = useRef({ recommendedList, eventDropdown, eventFilter, categoryDropdown, categoryFilter, eventStatus, statusFilter }).current

	useEffect(() => {
		if (previousProps.recommendedList !== recommendedList && recommendedList) {
			setShow(true)
		}
		return () => {
			previousProps.recommendedList = recommendedList
		}
	}, [recommendedList])

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div className='form-control date-range' onClick={onClick}>
			<img alt="calendar" src={calendarIcon} />
			<Input ref={ref} className='mx-2 range' placeholder='Select Date Range' readOnly value={value} />
		</div>
	))
	ExampleCustomInput.displayName = ExampleCustomInput

	// option for subcategory
	const options = subCategoryData?.map(data => ({
		value: data._id,
		label: `${data?.sName}`,
	})) || [];

	// option for status
	const statusOptions = [
		{ value: 'Y', label: 'Active' },
		{ value: 'N', label: 'Inactive' }
	]

	return (
		<div className="header-block">
			<div className="filter-block d-flex justify-content-between align-items-start fdc-480">
				<Form className="d-flex flex-wrap fdc-480 user-sub-header">
					{((!hidden)) && (
						<FormGroup className='form-group'>
							<Input className="search-box" name="search" onChange={handleSearch} autoComplete='off' onKeyPress={handleSearch} placeholder="Search" type="search" value={search} />
						</FormGroup>
					)}
					{(!props?.hideDateBox && !props?.eventTemplate)&& (
						<FormGroup className='form-group'>
							<DatePicker
								customInput={<ExampleCustomInput />}
								dropdownMode="select"
								endDate={endDate}
								isClearable={true}
								onChange={(update) => {
									setDateRange(update); dateFlag && (dateFlag.current = true)
								}}
								peekNextMonth
								placeholderText='Select Date Range'
								selectsRange={true}
								showMonthDropdown
								showYearDropdown
								startDate={startDate}
								value={dateRange}
							/>
						</FormGroup>
					)}
					{props?.events && (
						<>
							<FormGroup className='form-group'>
								<Input
									className="select-user-header w-100"
									// disabled={dateRange[0] === null}
									id="type"
									name="type"
									onChange={eventFilter}
									type="select"
									value={eventDropdown}
								>
									<option value="">All Event</option>
									<option value='m' >Manual Events</option>
									<option value='a'>Auto Events</option>
								</Input>
							</FormGroup>
							<FormGroup className='form-group'>
								<Input
									className="select-user-header w-100"
									// disabled={dateRange[0] === null}
									id="eventStatus"
									name="eventStatus"
									onChange={statusFilter}
									type="select"
									value={eventStatus}
								>
									<option value="">Select Status</option>
									<option value='c' >Completed</option>
									<option value='d' >Deleted</option>
									<option value='p' >Pending</option>
									<option value='s' >Started</option>
									<option value='su' >Suspended</option>
									<option value='po' >Pending Outcome</option>
								</Input>
							</FormGroup>
						</>
					)}
					{props?.eventTemplate && (
						<FormGroup className='form-group'>
							<Select
								className='season-select'
								isClearable
								onChange={(selectedOption) => onFiltering(selectedOption, 'eStatus')}
								options={statusOptions}
								placeholder='Status'
							/>
						</FormGroup>
					)}

					{props?.eventTemplate && (
						<FormGroup className='form-group'>
							<Select
								className='season-select'
								isClearable
								id="type"
								name="type"
								value={selectedCategory || null}
								onChange={(selectedOption) => onFiltering(selectedOption, 'category')}
								options={categoryList?.map((cat) => ({
									value: cat?._id,
									label: cat?.sName,
								}))}
								placeholder="Category"
							/>
						</FormGroup>
					)}

					{!props?.eventTemplate && (
						<FormGroup className='form-group'>
							<Input
								className="select-user-header w-100"
								// disabled={dateRange[0] === null}
								id="type"
								name="type"
								onChange={categoryFilter}
								type="select"
								value={categoryDropdown}
							>
								<option value="" key="category">Select Category</option>
								{categoryList?.map((cat) => {
									return <option value={cat._id} key={cat._id}>{cat?.sName}</option>
								})}
							</Input>
						</FormGroup>
					)}

					<FormGroup className='form-group'>
						<Select
							className='season-select'
							isClearable
							options={options}
							onMenuScrollToBottom={() => {
								if (hasNextPage && !isFetching) {
									fetchNextPage()
								}
							}}
							onChange={(selectedOption) => setSubCategoryId(selectedOption?.value)}
							placeholder='Subcategory'
						/>
					</FormGroup>
				</Form>
				{modalToggle && (
					<div className='d-flex'>
						<Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
							Manage Columns
						</Button>
					</div>
				)}
				<FormGroup className='d-flex'>
					{!props?.eventTemplate && (
						<div className='total-text-count me-2'>
							Total Events :
							{' '}
							<b>{list?.total || 0}</b>
						</div>
					)}
					{(props?.buttonText) && (
						<Button className={`theme-btn icon-btn`} tag={Link} to={props?.setUrl}>
							<img alt="add" src={addlIcon} />
							{props?.buttonText}
						</Button>
					)}
				</FormGroup>
			</div>
		</div>
	)
}

EventListHeader.propTypes = {
	handleSearch: PropTypes.func,
	onExport: PropTypes.func,
	search: PropTypes.string,
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	isDateRangeSelect: PropTypes.bool,
	searchBox: PropTypes.bool,
	handleModalOpen: PropTypes.func,
	recommendedList: PropTypes.arrayOf(PropTypes.object),
	list: PropTypes.object,
	user: PropTypes.bool,
	commonSearch: PropTypes.string,
	searchComplaint: PropTypes.bool,
	setModalMessage: PropTypes.func,
	buttonText: PropTypes.string,
	nameButton: PropTypes.string,
	handleChangeSearch: PropTypes.func,
	searchValue: PropTypes.string,
	onRefresh: PropTypes.func,
	normalUser: PropTypes.bool,
	permission: PropTypes.bool,
	setDateRange: PropTypes.func,
	dateRange: PropTypes.array,
	onClick: PropTypes.func,
	value: PropTypes.string,
	handleOtherFilter: PropTypes.func,
	events: PropTypes.bool,
	hidden: PropTypes.bool,
	// dateFlag: PropTypes.func,
	eType: PropTypes.string,
	onFilter: PropTypes.func,
	userType: PropTypes.string,
	handleUserType: PropTypes.func,
	reversedInfo: PropTypes.string,
	onReversedChange: PropTypes.func,
	dateFiltering: PropTypes.func,
	dateFilterDropDown: PropTypes.string,
	disableButton: PropTypes.bool,
	userSearch: PropTypes.string,
}

export default EventListHeader
