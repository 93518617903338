import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Login from "../views/Auth/Login"
import Dashboard from "../views/Dashboard"
import PublicRoute from "./PublicRoute"
import PrivateRoute from "./PrivateRoute"
import Layout from "../components/Layout"
import NotFound from "../components/NotFound"
import CMS from "../views/ContentManagement"
import AddContent from "../views/ContentManagement/AddContent"
import Roles from "../views/SubAdmin/Roles"
import IndexAddRole from "../views/SubAdmin/Roles/AddRole"
import AdminLogs from "../views/SubAdmin/AdminLogs"
import SingleLog from "../views/SubAdmin/AdminLogs/SingleLogDetails"
import SubAdmin from "../views/SubAdmin"
import AddSubAdmin from "../views/SubAdmin/AddSubAdmin/index"
import AddSlider from "../views/Settings/SliderManagement/AddSlider/index"
import SliderStatistics from "../views/Settings/SliderManagement/SliderStatistics"
import SliderManagement from "../views/Settings/SliderManagement"
import Versions from "../views/Settings/Versions"
import AddVersionIndex from "../views/Settings/Versions/AddVersion"
// Users pages
import UserManagement from '../views/Users/UserManagement/index'
import UserDetails from '../views/Users/UserManagement/UserDetails'
import KYCVerification from "../views/Users/KYCVerification"
import UserKycVerification from "../views/Users/KYCVerification/UserKycRequest"
import ReferralIndex from "../views/Users/UserManagement/UserDetails/User Referrals"
import CategoryManagement from "../views/CategoriesManagement/Category"
import SubCategoryManagement from "../views/CategoriesManagement/SubCategory"
import AddCategoryIndex from "../views/CategoriesManagement/Category/AddCategory"
import AddSubCategoryIndex from "../views/CategoriesManagement/SubCategory/AddSubCategory"
import SettingManagement from "../views/Settings/SettingManagement"
import AddSetting from "../views/Settings/SettingManagement/AddSetting/index"
import EventManagement from "../views/Events"
import AddEvent from "../views/Events/AddEvent"
import UserProfileManagemenet from "../views/UserProfileLevel"
import AddUserXP from "../views/UserProfileLevel/AddUserXP"
import OrderManagement from "../views/Events/OrderManagement"
import PushNotification from "../views/Users/PushNotification"
import IndexAutomatedNotification from "../views/Users/PushNotification/AutomatedNotification"
import IndexUpdatePushNotification from "../views/Users/PushNotification/UpdatePushNotification"
import PassbookManagement from '../views/Users/PassbookManagement/index'
import TransactionReportIndex from '../views/Users/PassbookManagement/TransactionReport'
import WithdrawManagement from '../views/Users/WithdrawManagement/index'
import DepositManagement from '../views/Users/DepositManagement/index'
import TDS from '../views/Users/TDS'
import EventDetailsIndex from "../views/Events/EventDetails"
import AddOrder from "../views/Events/OrderManagement/AddOrder"
import SellOrder from "../views/Events/OrderManagement/SellOrder"
import AllReports from "../views/Reports"
import ChatSupport from "../views/ChatSupport"
import EventReport from "../views/Events/EventDetails/EventReport"
import SubCategoryReport from "../views/CategoriesManagement/SubCategory/SubCategoryReport"
import CommonRules from "../views/Settings/CommonRules"
import AddCommonRules from '../views/Settings/CommonRules/AddCommonRules/index'
import AllUserStreaks from "../views/Streaks"
import StreaksIndex from "../views/Streaks/StreakDetails"
import EventTempIndex from "../views/Events/EventTemplates"
import AddUpdateEventTmep from "../views/Events/EventTemplates/AddUpdateEventTemplates"

const OtRoutes = () => {
  return <Router>
    <Routes>
      <Route element={<PublicRoute><Login /></PublicRoute>} exact path='/auth/login' />
      <Route element={<Layout />}>

        {/* Dashboard Route */}
        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} exact path='/dashboard' />
        {/* Content Management Routes */}
        <Route element={<PrivateRoute><CMS /></PrivateRoute>} exact path='/content-management/content' />
        <Route element={<PrivateRoute><CMS /></PrivateRoute>} exact path='/content-management' />
        <Route element={<PrivateRoute><AddContent /></PrivateRoute>} exact path="/content-management/add-content" />
        <Route element={<PrivateRoute><AddContent /></PrivateRoute>} exact path='/content-management/content-details/:slug'/>

        <Route element={<PrivateRoute><PushNotification /></PrivateRoute>} exact path='/content-management/push-notification'/>
      <Route element={<PrivateRoute><IndexAutomatedNotification /></PrivateRoute>} exact path='/content-management/push-notification/automated-notification'/>
      <Route element={<PrivateRoute><IndexUpdatePushNotification /></PrivateRoute>} exact path='/content-management/push-notification-details/:id'/>

        <Route element={<PrivateRoute><SliderManagement /></PrivateRoute>} exact path='/content-management/slider-management' />
        <Route element={<PrivateRoute><AddSlider /></PrivateRoute>} exact path='/content-management/add-slider' />
        <Route element={<PrivateRoute><AddSlider /></PrivateRoute>} exact path='/content-management/slider-details/:id' />
        <Route element={<PrivateRoute><SliderStatistics /></PrivateRoute>} exact path='/content-management/slider-statistics/:id'/>

        {/* Admin management Route */}
        <Route element={<PrivateRoute><Roles /></PrivateRoute>} exact path='/admin-management/roles' />
        <Route element={<PrivateRoute><IndexAddRole /></PrivateRoute>} exact path='/admin-management/add-role' />
        <Route element={<PrivateRoute><IndexAddRole /></PrivateRoute>} exact path='/admin-management/update-role/:id' />
        <Route element={<PrivateRoute><AdminLogs /></PrivateRoute>} exact path='/admin-logs' />
        <Route element={<PrivateRoute><SingleLog /></PrivateRoute>} exact path='/admin-logs/single-log-details/:id' />
        <Route element={<PrivateRoute><AdminLogs /></PrivateRoute>} exact path='/admin-logs/:id' />
        <Route element={<PrivateRoute><AdminLogs /></PrivateRoute>} exact path='/admin-logs/logs/:leagueid' />
        
        <Route element={<PrivateRoute><SubAdmin /></PrivateRoute>} exact path='/admin-management' />
        <Route element={<PrivateRoute><AddSubAdmin /></PrivateRoute>} exact path='/admin-management/add-admin' />
        <Route element={<PrivateRoute><AddSubAdmin /></PrivateRoute>} exact path='/admin-management/edit-admin/:id' />

        {/* Categroy Management Route */}
        <Route element={<PrivateRoute><CategoryManagement /></PrivateRoute>} exact path='/category-management/category' />
        <Route element={<PrivateRoute><AddCategoryIndex /></PrivateRoute>} exact path='/category-management/edit-category/:id' />
        <Route element={<PrivateRoute><SubCategoryManagement /></PrivateRoute>} exact path='/category-management/subcategory' />
        <Route element={<PrivateRoute><AddSubCategoryIndex /></PrivateRoute>} exact path='/category-management/edit-subcategory/:id' />
        <Route element={<PrivateRoute><AddSubCategoryIndex /></PrivateRoute>} exact path='/category-management/add-subcategory' />
        <Route element={<PrivateRoute><SubCategoryReport /></PrivateRoute>} exact path='/subcategory/report/:id' />

        {/* Settings Route */}
        <Route element={<PrivateRoute><CommonRules /></PrivateRoute>} exact path='/settings/common-rules' />
        <Route element={<PrivateRoute><AddCommonRules /></PrivateRoute>} exact path='/settings/common-rules-details/:id' />
        <Route element={<PrivateRoute><AddCommonRules /></PrivateRoute>} exact path='/settings/add-common-rule'/>
        <Route element={<PrivateRoute><Versions /></PrivateRoute>} exact path='/settings/versions' />
        <Route element={<PrivateRoute><AddVersionIndex /></PrivateRoute>} exact path='/settings/add-version' />
        <Route element={<PrivateRoute><AddVersionIndex /></PrivateRoute>} exact path='/settings/version-details/:id' />
        <Route element={<PrivateRoute><SettingManagement /></PrivateRoute>} exact path='/settings/setting-management'/>
        <Route element={<PrivateRoute><AddSetting /></PrivateRoute>} exact path='/settings/add-setting'/>
        <Route element={<PrivateRoute><AddSetting /></PrivateRoute>} exact path='/settings/setting-details/:id'/>

        {/* User Routes */}
        <Route element={<PrivateRoute><UserManagement /></PrivateRoute>} exact path='/users/user-management' />
        <Route element={<PrivateRoute><UserDetails /></PrivateRoute>} exact path='/users/user-management/user-details/:id' />
        <Route element={<PrivateRoute><UserManagement /></PrivateRoute>} exact path='/users/deleted-users' />
        <Route element={<PrivateRoute><KYCVerification /></PrivateRoute>} exact path='/users/kyc-verification' />
        <Route element={<PrivateRoute><UserKycVerification /></PrivateRoute>} exact path='/users/user-update-status/:id' />
        <Route element={<PrivateRoute><ReferralIndex /></PrivateRoute>} exact path='/users/user-referred-list/:id' />

        {/* Event Routes  */}
        <Route element={<PrivateRoute><EventManagement /></PrivateRoute>} exact path='/events/event-management' />
        <Route element={<PrivateRoute><AddEvent /></PrivateRoute>} exact path='/event-management/add-event' />
        <Route element={<PrivateRoute><AddEvent /></PrivateRoute>} exact path='/event-management/edit-event/:id' />
        <Route element={<PrivateRoute><EventDetailsIndex /></PrivateRoute>} exact path='/event-management/event-details/:id' />
        <Route element={<PrivateRoute><OrderManagement /></PrivateRoute>} exact path='/orders/trade-history' />
        <Route element={<PrivateRoute><AddOrder /></PrivateRoute>}  path='/order-management/add-order' />
        <Route element={<PrivateRoute><SellOrder /></PrivateRoute>}  path='/order-management/sell-order' />
        <Route element={<PrivateRoute><EventReport /></PrivateRoute>}  path='/event-management/event-report/:id' />
        {/* <Route element={<PrivateRoute><OrderManagement /></PrivateRoute>} exact path='/event/orders' /> */}
        <Route element={<PrivateRoute><EventTempIndex /></PrivateRoute>} exact path='/events/event-template' />
        <Route element={<PrivateRoute><AddUpdateEventTmep /></PrivateRoute>} exact path='/events/event-template/update/:id' />
        <Route element={<PrivateRoute><AddUpdateEventTmep /></PrivateRoute>} exact path='/events/event-template/add' />
        
        {/* User XP Routes  */}
        <Route element={<PrivateRoute><UserProfileManagemenet /></PrivateRoute>} exact path='/user-profile-level' />
        <Route element={<PrivateRoute><AddUserXP /></PrivateRoute>} exact path='/user-profile-level/add-level' />
        <Route element={<PrivateRoute><AddUserXP /></PrivateRoute>} exact path='/user-profile-level/add-level/:id' />

        {/* Transaction */}
        <Route element={<PrivateRoute><PassbookManagement /></PrivateRoute>} exact path='/transaction/passbook' />
        <Route element={<PrivateRoute><PassbookManagement /></PrivateRoute>} exact path='/transaction/passbook/:id' />
        <Route element={<PrivateRoute><TransactionReportIndex /></PrivateRoute>} exact path='/transaction/transaction-report' />
        <Route element={<PrivateRoute><WithdrawManagement /></PrivateRoute>} exact path='/transaction/withdraw-management' />
        <Route element={<PrivateRoute><DepositManagement /></PrivateRoute>} exact path='/transaction/deposit-management' />
        <Route element={<PrivateRoute><TDS /></PrivateRoute>} exact path='/transaction/tds-management' />
        <Route element={<PrivateRoute><TDS /></PrivateRoute>} exact path='/transaction/tds-management/:id' />

        {/* All Reports  */}
        <Route element={<PrivateRoute><AllReports /></PrivateRoute>} exact path='/reports/all-reports'/>
        {/* User Streaks  */}
        <Route element={<PrivateRoute><AllUserStreaks /></PrivateRoute>} exact path='/user-streak'/>
        <Route element={<PrivateRoute><StreaksIndex /></PrivateRoute>} exact path='/user-streak/streak-details/:id'/>
        {/* Chat Support  */}
        <Route element={<PrivateRoute><ChatSupport /></PrivateRoute>} exact path='/chat-support'/>

        <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path="/" />
      </Route>
      <Route element={<PrivateRoute><NotFound /></PrivateRoute>} />
    </Routes>
  </Router>
}
export default OtRoutes