import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext } from 'react'
import { Button, Modal, ModalBody, Row, Col, UncontrolledTooltip, Badge } from 'reactstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import { useDispatch, useSelector } from 'react-redux'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'
import warningIcon from '../../../assets/images/error-warning.svg'
import infoIcon from '../../../assets/images/info-icon.svg'

import Loading from '../../../components/Loading'
// import SkeletonTable from '../../../components/SkeletonTable'
import PaginationComponent from '../../../components/PaginationComponent'
import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import { modalMessageFunc } from '../../../helpers/helper'
import { useMutation } from '@tanstack/react-query'
import { cancelOrder, sellOrder } from '../../../actions/orders'
import SocketContext from '../../../context/SocketContext'
import { ORDER_LIST, ORDERS_TOTAL_COUNT } from '../../../actions/constants'

const OrderList = forwardRef((props, ref) => {
    const { List, resStatus, resMessage, getList, flag, startDate, endDate, filter, getOrderTotalCount, ordersTotalCount, eventOrderStatus, eventOrderType, eventBidType, bIsAdminOrders,  platform,  utmSource,  dateFromTOdateTO, columnNames } = props
    const location = useLocation()
    const { id } = useParams()
    const exporter = useRef(null)
    const dispatch = useDispatch()
    const searchProp = props?.search
    const [isFullResponse] = useState(false)
    const [fullList, setFullList] = useState([])
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('')
    const [startingNo, setStartingNo] = useState(0)
    const [endingNo, setEndingNo] = useState(0)
    const [total, setTotal] = useState(0)
    const [index, setIndex] = useState(1)
    const [list, setList] = useState([])
    const [activePageNo, setPageNo] = useQueryState('page', 1)
    // eslint-disable-next-line no-unused-vars
    const [dateFrom, setDateFrom] = useQueryState('datefrom', '')
    // eslint-disable-next-line no-unused-vars
    const [dateTo, setDateTo] = useQueryState('dateto', '')
    // const [filterBy, setFilterBy] = useState('')
    const [order] = useQueryState('order', 'desc')
    const [start, setStart] = useState(0)
    const [offset, setOffset] = useQueryState('pageSize', 10)
    const [sort] = useQueryState('sortBy', 'dCreatedAt')
    const [search, setSearch] = useQueryState('searchvalue', '')
    const [listLength, setListLength] = useState('10 Rows')
    const [close, setClose] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [type, setType] = useState('')
    const [modalWarning, setModalWarning] = useState(false)
    const [modalMessage, setModalMessage] = useState(false)
    const socket = useContext(SocketContext)

    const toggleWarning = () => setModalWarning(!modalWarning)
    const isFullList = useSelector(state => state?.users?.isFullResponse)
    const obj = qs.parse(location?.search)
    const deletedUsers = location?.pathname?.includes('deleted-users')
    const previousProps = useRef({ List, resStatus, resMessage, startDate, endDate, activePageNo, start, offset, filter, deletedUsers, platform, utmSource, eventOrderStatus, eventBidType, eventOrderType,bIsAdminOrders })?.current
    const paginationFlag = useRef(false)
    const navigate = useNavigate()
    // useEffect to set Query Param form url and also set Message from navigate
    useEffect(() => {
        if (location?.state) {
            if (location?.state?.message) {
                setMessage(location?.state?.message)
                setStatus(true)
                setModalMessage(true)
            }
            navigate(location?.pathname, { replace: true })
        }
        let page = 1
        let limit = offset
        let searchText = ''
        if (obj) {
            if (obj?.page) {
                page = obj?.page
                setPageNo(page)
            }
            if (obj?.pageSize) {
                limit = obj?.pageSize
                setOffset(limit)
                setListLength(`${limit} Rows`)
            }
            if (obj?.searchvalue) {
                searchText = obj?.searchvalue
                setSearch(obj?.searchvalue)
            }

            if (!(obj?.datefrom && obj?.dateto)) {
                const startFrom = (page - 1) * offset
                setStart(startFrom)
                getList(startFrom, limit, sort, order, searchText, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
                getOrderTotalCount(searchText, props?.filter, startDate, endDate, platform, utmSource)

            }
        }
        setLoading(true)
    }, [])
    // will be called when something searched
    useEffect(() => {
        const callSearchService = () => {
            const startFrom = 0
            const limit = offset
            getList(startFrom, limit, sort, order, props.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
            getOrderTotalCount(props?.search, props?.filter, startDate, endDate, platform, utmSource)

            setSearch(searchProp?.trim())
            setStart(startFrom)
            setPageNo(1)
            setLoading(true)
        }
        if (previousProps?.searchProp !== searchProp && flag) {
            const debouncer = setTimeout(() => {
                callSearchService()
            }, 1000)
            return () => {
                clearTimeout(debouncer)
                previousProps.searchProp = searchProp
            }
        }
        return () => {
            previousProps.searchProp = searchProp
        }
    }, [searchProp])

    // handle to set filter
    useEffect(() => {
        if (previousProps?.filter !== filter || previousProps?.eventOrderStatus !== eventOrderStatus || previousProps?.eventBidType !== eventBidType || previousProps?.eventOrderType !== eventOrderType || previousProps?.bIsAdminOrders !== bIsAdminOrders ) {
            const startFrom = 0
            const limit = offset
            const filterBy = props?.filter
            getList(startFrom, limit, sort, order, props?.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
            getOrderTotalCount(search, filterBy, startDate, endDate)
            setStart(startFrom)
            setPageNo(1)
            setLoading(true)
        }
        return () => {
            previousProps.filter = filter
            previousProps.eventOrderStatus = eventOrderStatus
            previousProps.eventBidType = eventBidType
            previousProps.eventOrderType = eventOrderType
            previousProps.bIsAdminOrders = bIsAdminOrders
        }
    }, [filter, eventOrderStatus, eventBidType, eventOrderType,bIsAdminOrders])

    // handle to set startDate and EndDate
    useEffect(() => {
        if (previousProps?.startDate !== startDate || previousProps?.endDate !== endDate) {
            if (props?.startDate && props?.endDate) {
                const startFrom = (obj && obj?.datefrom && obj?.dateto && obj?.page) ? (obj?.page - 1) * offset : 0
                const limit = offset
                getList(startFrom, limit, sort, order, search, props?.filter, props?.startDate, props?.endDate, platform, utmSource, isFullResponse)
                getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)


                setDateFrom(new Date(moment(startDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
                setDateTo(new Date(moment(endDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))

                if ((obj && obj?.datefrom && obj?.dateto && obj?.page)) {
                    setPageNo(obj?.page)
                } else {
                    setPageNo(1)
                }
                setLoading(true)
            } else if ((!props?.startDate) && (!props?.endDate)) {
                const startFrom = 0
                const limit = offset
                getList(startFrom, limit, sort, order, search, props?.filter, props?.startDate, props?.endDate, platform, utmSource, isFullResponse)
                getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)

                setDateFrom('')
                setDateTo('')
                setPageNo(1)
                setLoading(true)
            }
        }
        return () => {
            previousProps.startDate = startDate
            previousProps.endDate = endDate
        }
    }, [startDate, endDate])

    // set timeout to remove pop up success/error message after given interval
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])
    // handle to set user List
    useEffect(() => {
        if (previousProps?.List !== List) {
            if (List && !isFullList) {
                const userArrLength = List?.length
                const startFrom = (activePageNo - 1) * offset + 1
                const end = startFrom - 1 + userArrLength
                setStartingNo(startFrom)
                setEndingNo(end)
                setList(List)
                setIndex(activePageNo)
                setLoading(false)
            } else if (isFullList) {
                setFullList(List ? List : [])
                setLoading(false)
                exporter.current.props = {
                    ...exporter?.current?.props,
                    data: processExcelExportData(List ? List : []),
                    fileName: 'Orders.xlsx'
                }
                exporter?.current?.save()
                setLoader(false)
            }
        }
        setTotal(ordersTotalCount)
        return () => {
            previousProps.List = List
            previousProps.ordersTotalCount = ordersTotalCount
        }
    }, [List, ordersTotalCount])
    // handle to set resMessage
    useEffect(() => {
        if (previousProps?.resMessage !== resMessage) {

            if (resMessage) {
                if (resStatus) {
                    const startFrom = 0
                    const limit = offset
                    getList(startFrom, limit, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
                    getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)
                    setMessage(resMessage)
                    setStatus(resStatus)
                    setModalMessage(true)
                    const obj = qs.parse(location?.search)
                    setPageNo(obj?.page ? obj.page :1)
                } else {
                    setMessage(resMessage)
                    setStatus(resStatus)
                    setModalMessage(true)
                    setLoading(false)
                }
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resStatus, resMessage])

    // useEffect to handle QueryParams
    // useEffect(() => {
    //     let data = localStorage?.getItem('queryParams')
    //         ? JSON?.parse(localStorage?.getItem('queryParams'))
    //         : {}
    //     !Object?.keys(data)?.length
    //         ? (data = { EventManagement: location?.search })
    //         : (data.EventManagement = location?.search)
    //     localStorage?.setItem('queryParams', JSON?.stringify(data))
    // }, [location?.search])
    useEffect(() => {
        if (socket) {
            socket.on('ADMIN_UPDATES', (data) => {
                if (data?.eType === 'PLACE_ORDER') {
                    const newList = Array.isArray(List) ? [...List] : [];
                    if (newList.length > 0) {
                        newList.pop();
                    }
                    let newObj = { ...data.oData.oOrderInfo };
                    newObj.iEventId = data?.oData?.iEventId;
                    newObj.iUserId = data?.oData?.iUserId;
                    newList.unshift(newObj);
                    const newCount = ordersTotalCount + 1;
                    dispatch({
                        type: ORDERS_TOTAL_COUNT,
                        payload: {
                            data: newCount,
                            resStatus: true
                        }
                    });
                    dispatch({
                        type: ORDER_LIST,
                        payload: {
                            data: newList || [],
                            resStatus: true,
                            resMessage: '',
                            isFullResponse: false
                        }
                    });
                    // Optional: Call other functions as needed
                    // getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse, bIsAdminOrders);
                    // getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource, bIsAdminOrders);
                }
            });
        }
    }, [socket, List, ordersTotalCount, dispatch]);
      

    // will be called when page changes occurred
    useEffect(() => {
        if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current && start) {
            getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
            getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)
            setLoading(true)
        } else if (previousProps?.offset !== offset) {
            getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
            getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)
            setLoading(true)
        } else if (((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current)) {
            getList(0, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
            getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)
            setLoading(true)
        }
        return () => {
            previousProps.start = start
            previousProps.offset = offset
        }
    }, [start, offset])

    // function status and dispatch updateUseDetails
    function onStatusUpdate() {
        if(type==='Cancel'){
            const cancelOrderData = {
                id,
                orderIds: selectedData
            }
            dispatch(cancelOrder(cancelOrderData))
        } else {
            const newItem = {
                eOrderType: selectedData[0]?.eOrderType,
                nQty: selectedData[0]?.nQty,
                nPrice: selectedData[0]?.nPrice,
                sSymbol: selectedData[0]?.sSymbol,
            };
            if (selectedData[0]?.iOrderId) {
                newItem.iBuyOrderId = selectedData[0].iOrderId;
            }
            const sellOrderData = {
                eventId: id,
                bIsSellOrder: false,
                orders: [
                    newItem
                ]
            }
            dispatch(sellOrder(sellOrderData))
        }
        setLoading(true)
        toggleWarning()
        setSelectedData({})
    }

    // export list
    const processExcelExportData = (data) => data?.map((userList, index) => {
        const srNo = index + 1
        const sName = userList?.sName || '-'
        let dCreatedAt = moment(userList?.dCreatedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
        dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
        const dLoginAt = userList?.dLoginAt ? moment(userList?.dLoginAt)?.local()?.format('lll') : '-'
        const ePlatform = userList?.ePlatform ? (userList?.ePlatform === 'I' ? 'iOS' : userList?.ePlatform === 'W' ? 'Web' : userList?.ePlatform === 'A' ? 'Android' : '-') : '-'
        let dDeletedAt = moment(userList?.dDeletedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
        dDeletedAt = dDeletedAt === 'Invalid date' ? ' - ' : dDeletedAt
        const sUtmSource = userList?.oUtm?.sUtmSource || '-'
        return { ...userList, dLoginAt, srNo, dCreatedAt, sName, ePlatform, dDeletedAt, sUtmSource }
    })

    const exportMutation = useMutation(() => getList(0, offset, sort, order, props?.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse), {
        onSuccess: (data) => {
            if (data?.data?.data[0]?.results) {
                exporter.current.props = {
                    ...exporter?.current?.props,
                    data: processExcelExportData(data?.data?.data[0]?.results || []),
                    fileName: 'Orders.xlsx'
                }
                exporter?.current?.save()
            }
        }
    })
    async function onExport() {
        if (startDate && endDate) {
            exportMutation?.mutate()
            // setLoader(true)
        } else {
            setMessage('Please Select Date Range')
            setModalMessage(true)
            setStatus(false)
        }
    }

    function onRefresh() {
        getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
        getOrderTotalCount(search, props?.filter, startDate, endDate, platform, utmSource)

        setLoading(true)
        setPageNo(activePageNo)
    }

    useImperativeHandle(ref, () => ({
        onExport,
        onRefresh
    }))
    const handleCancelOrder = (eType, orderId) => {
        setType(eType)
        if(eType==='Sell'){
            navigate(`/order-management/sell-order?eventId=${id}&orderId=${orderId?.iOrderId}`)
        }else{
            setModalWarning(true)
            setSelectedData([orderId])
        }
        // setSelectedData([id])
    }
    function mapData(data, i) {
        const newData = columnNames?.filter(c => c?.bShow)
        const tableCells = []
        for (let k = 0; k < newData?.length; k++) {
            const column = newData[k].sColumnName
            switch (column) {
                case 'No.':
                    tableCells.push(<td key={k}>{(((index - 1) * offset) + (i + 1))}</td>)
                    break
                case 'UserName':
                    tableCells.push(
                        <td key={k}>
                            {(data && data?.sUserName) || '--'}
                        </td>
                    )
                    break
                case 'Bid Type':
                    tableCells.push(
                        <td key={k} style={data?.eBidType === 'BUY' ? { color: 'green' } : { color: 'red' }}>
                            <span className="red-dot"
                                style={data?.eBidType === 'BUY' ? { backgroundColor: 'green' } : { backgroundColor: 'red' }}
                            //  style={{display:"inline-block",width:'8px',height:'8px',borderRadius:'50%',backgroundColor:'red',marginRight:'5px',verticalAlign:'middle'}}
                            ></span>
                            {data?.eBidType || '--'}
                        </td>
                    )
                    break
                case 'Order Type':
                    tableCells.push(
                        <td key={k}>
                            {data?.eOrderType || '--'}
                        </td>
                    )
                    break
                case 'Quantity':
                    tableCells.push(<td key={k}>{data?.nQty || '--'}</td>)
                    break
                case 'Remaining Quantity':
                    tableCells.push(<td key={k}>{data?.nRemainingQty || '--'}</td>)
                    break
                case 'Buy Type':
                    tableCells.push(<td key={k}>{data?.sSymbol || '--'}</td>)
                    break
                case 'Price':
                    tableCells.push(<td key={k}>{data?.nPrice || '--'}</td>)
                    break
                case 'FilledQTY':
                    tableCells.push(<td key={k}>{data?.nFilledQty || '--'}</td>)
                    break
                case 'Order Status':
                    tableCells.push(
                        <td key={k}>
                            {data?.eOrderStatus === 'SETTLED' ? (<Badge tag="span" className='match-status-cancl ml-2' color='#E6FAEF' >Settled</Badge>) : ('')}
                            {data?.eOrderStatus === 'PENDING' ? (<Badge tag="span" className='match-status-p ml-2' color='#fcf2e8 '>Pending</Badge>) : ('')}
                            {data?.eOrderStatus === 'CANCEL' ? (<Badge className='match-status-r ml-2' color='#FEECF0'>Cancel</Badge>) : ('')}
                            {data?.eOrderStatus === 'MATCHED' ? (<Badge className='match-status-cmp ml-2' color='#e6faef'> Matched</Badge>) : ('')}
                            {data?.eOrderStatus === 'PARTIALLY_FILLED' ? (<Badge className='match-status-u ml-2' color='#f3ebff'>Partially Filled</Badge>) : ('')}
                            {data?.eOrderStatus === 'CANCELLED' ? (<Badge className='match-status-r ml-2' color='#FEECF0'>Cancelled</Badge>) : ('')}
                        </td>)
                    break
                case 'Order Date':
                    tableCells.push(<td key={k}>{moment(data?.dCreatedAt)?.format('lll')}</td>)
                    break
                case 'Book Profit':
                    tableCells.push(<td key={k}>{data?.nBookProfit || '0'}</td>)
                    break
                case 'Stop Loss':
                    tableCells.push(<td key={k}>{data?.nStopLoss || '0'}</td>)
                    break
                case 'Actions':
                    tableCells.push(
                        <td key={k}>
                            <ul className="action-list mb-0 d-flex p-0">
                                <li>
                                    {data?.eUserType === 'U' ? '' :
                                        <>
                                            {/* <Button className='badge-success border-0 me-2' onClick={()=>handleCancelOrder('Sell',data)} disabled={data?.eUserType === 'U' ||data?.eOrderStatus === 'SETTLED' ||  data?.eBidType==='SELL' || data?.nFilledQty < data?.nSellQty || data?.nFilledQty === data?.nSellQty ? true : false}>Sell</Button> */}
                                            {(data?.eOrderStatus !== 'PARTIALLY_FILLED' && data?.eOrderStatus !== 'CANCEL') &&
                                                <Button className='badge-danger border-0' onClick={() => handleCancelOrder('Cancel', data?.iOrderId)} disabled={data?.eOrderStatus === 'MATCHED' ||data?.eOrderStatus === 'CANCELLED' || data?.eOrderStatus === 'SETTLED'  ? true : false}>Cancel</Button>
                                            }
                                        </>
                                    }
                                </li>
                            </ul>
                        </td>
                    )
                    break
                default:
                    tableCells.push(<td key={k}>--</td>)
            }
        }

        return <tr key={i}>{tableCells}</tr>
    }

    return (
        <Fragment>
            <AlertMessage
                close={close}
                message={message}
                modalMessage={modalMessage}
                status={status}
            />
            <ExcelExport ref={exporter} data={fullList && fullList?.length > 0 ? fullList : list} fileName={`User-${dateFromTOdateTO}.xlsx`}>
                <ExcelExportColumn field='srNo' title='Sr No' />
                <ExcelExportColumn field='sUsername' title='Username' />
                <ExcelExportColumn field='sEmail' title='Email' />
                <ExcelExportColumn field='sMobNum' title='Mobile No.' />
                <ExcelExportColumn field='ePlatform' title='Platform' />
                <ExcelExportColumn field='sUtmSource' title='UTM Source' />
                <ExcelExportColumn field='dLoginAt' title='Last Login Time' />
                <ExcelExportColumn field='dCreatedAt' title='Registration Date' />
            </ExcelExport>
            {loader && <Loading />}
            {!loading && list?.length === 0
                ? (<DataNotFound message="Order List" obj={obj} />)
                : (
                    <div className='table-represent'>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        {columnNames?.map((c, i) => {
                                            if (c?.sColumnName === 'Status' && c?.bShow && !deletedUsers) {
                                                return (
                                                    <th key={i}>
                                                        Status
                                                        <img className="custom-info ml-1" id="info" src={infoIcon} alt='info' />
                                                        <UncontrolledTooltip className="bg-default-s" delay={0} placement="right" target="info">
                                                            <p>
                                                                After deactivate any user from here, It will take maximum 5 minute to reflect on the app.
                                                            </p>
                                                        </UncontrolledTooltip>
                                                    </th>
                                                )
                                            } else if (c?.bShow) {
                                                return <th key={i}>{c?.sColumnName}</th>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </tr>
                                </thead>

                                <tbody>
                                    {list && list?.length && list?.map((data, i) => {
                                        return (mapData(data, i)
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

            {list?.length !== 0 && (
                <PaginationComponent
                    activePageNo={activePageNo}
                    endingNo={endingNo}
                    listLength={listLength}
                    offset={offset}
                    paginationFlag={paginationFlag}
                    setListLength={setListLength}
                    setLoading={setLoading}
                    setOffset={setOffset}
                    setPageNo={setPageNo}
                    setStart={setStart}
                    startingNo={startingNo}
                    total={total}
                    orderList
                />
            )}

            <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
                <ModalBody className='text-center'>
                    <img alt='check' className='info-icon' src={warningIcon} />
                    <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
                    <Row className='row-12'>
                        <Col>
                            <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={toggleWarning} type='submit'>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className='theme-btn danger-btn full-btn' onClick={onStatusUpdate} type='submit'>{`${type} Now`}</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
})

OrderList.propTypes = {
    location: PropTypes.object,
    openPicker: PropTypes.bool,
    search: PropTypes.string,
    // List: PropTypes.object,
    resStatus: PropTypes.bool,
    resMessage: PropTypes.string,
    getList: PropTypes.func,
    flag: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    viewLink: PropTypes.string,
    searchBox: PropTypes.string,
    history: PropTypes.object,
    // filter: PropTypes.string,
    getOrderTotalCount: PropTypes.func,
    // ordersTotalCount: PropTypes.object,
    onRefresh: PropTypes.func,
    getDeletedUsers: PropTypes.func,
    setSearchProp: PropTypes.func,
    setFilter: PropTypes.func,
    setinitialFlag: PropTypes.func,
    setDateRange: PropTypes.func,
    platform: PropTypes.string,
    setPlatform: PropTypes.func,
    dateFromTOdateTO: PropTypes.string,
    utmSource: PropTypes.string,
    setUtmSource: PropTypes.func,
    columnNames: PropTypes.array
}

OrderList.displayName = OrderList

export default OrderList
