import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import backIcon from '../../../assets/images/back-icon-1.svg'
import exportIcon from '../../../assets/images/export-icon.svg'

// common header for user,delete user and Kyc Details
function EventListMainHeader (props) {
  const { 
    heading,
    list,
    EventDetails,
    AddUpdateEventTemplate,
    submitDisableButton,
    onSubmit,
    Auth,
    adminPermission
  } = props

  const location = useLocation()
  const navigate = useNavigate()

  const page = JSON.parse(localStorage.getItem('queryParams'))

  return (
    <div className="header-block-main-user">
      <div className="d-flex justify-content-between align-items-center">
        <div className='d-flex inline-input'>

          {(AddUpdateEventTemplate)
            ? <img className='custom-go-back mr-2' alt="" height='22' onClick={() => { navigate(-1) }} src={backIcon} style={{ marginTop: '2px' }} width='22' />
            : ''}
          {EventDetails && (
            <img
            alt='go-back'
              className='custom-go-back ml-3'
              height='24'
              onClick={() => (location?.state && location?.state?.userList)
                ? navigate(-3)
                : location?.state?.isSeriesLeaderBoardUserRank
                  ? navigate(location?.state?.SeriesLeaderBoardUserRankLink)
                  : (location?.state && location?.state?.userList)
                      ? navigate(`/events/event-management${page?.EventManagement || ''}`)
                      : navigate(-1)}
              src={backIcon}
              width='24'
            />
          )}
          {window.innerWidth <= 480
            ? (
              <div>
                <h3 className='mb-0 ml-3'>{heading}</h3>
                </div>
              )
            : (
              <h2 className='user-heading-h2 ml-3'>
                {heading}
              </h2>
              )}
        </div>

        <div className="btn-list-user">
          {AddUpdateEventTemplate &&
            <Button className="theme-btn icon-btn-cancel " tag={Link} onClick={() => { navigate(-1) }}>
              Cancel
            </Button>
          }

          {props.onExport && list && ((list.rows && list.rows.length > 0) || (list.results && list.results.length > 0) || (list.total > 0) || (list.length > 0)) && (
          <Button className="theme-btn icon-btn-export " onClick={props.onExport}>
            <img alt="add" src={exportIcon} />
            Export
          </Button>
          )}
          {props.refresh && (
            <Button className="theme-btn icon-btn-refresh  refresh" onClick={props.onRefresh}>
              {props.refresh}
            </Button>
          )}

          {AddUpdateEventTemplate && ((Auth && Auth === 'SUPER') || (adminPermission?.PAYMENT_OPTION !== 'R')) && (
            <Button className="theme-btn" disabled={submitDisableButton} onClick={onSubmit}>Save Changes</Button>
          )}
        </div>
      </div>
    </div>
  )
}
EventListMainHeader.propTypes = {
  onExport: PropTypes.func,
  // refresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  userDetailsPage: PropTypes.string,
  isTdsToPassbook: PropTypes.bool,
  isLeagueToPassbook: PropTypes.bool,
  isLeagueToTds: PropTypes.bool,
  // heading: PropTypes.string,
  list: PropTypes.object,
  leagueToTdsMatch: PropTypes.string,
  leagueToTdsLeague: PropTypes.string,
  leagueToPassbookMatch: PropTypes.string,
  leagueToPassbookId: PropTypes.string,
  leagueToTdsId: PropTypes.string,
  leagueToPassbookLeague: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  UserDetails: PropTypes.bool,
  SystemUserDetails: PropTypes.bool,
  UpdatePushNotification: PropTypes.bool,
  submitDisableButton: PropTypes.bool,
  onSubmit: PropTypes.func,
  cancelLink: PropTypes.string,
  UserDebugger: PropTypes.bool,
  systemUser: PropTypes.bool,
  isTransactionReport: PropTypes.bool,
  isSeriesLeaderBoardUserRank: PropTypes.bool,
  SeriesLeaderBoardUserRankLink: PropTypes.string,
  RefferalsDetails: PropTypes.bool,
  UpdateAutomatedNotification: PropTypes.bool,
  AddSystemAgent: PropTypes.bool,
  button: PropTypes.string,
  systemName: PropTypes.bool

}

export default EventListMainHeader
