import axios from '../../axios'

export function getEventTemplates(start, limit, search, eStatus, iCategoryId, iSubCategoryId, isFullResponse = false) {
  return axios.get(`/ot/admin/event-template/get-list/v1?start=${start}&limit=${limit}&search=${search}&eStatus=${eStatus}&iCategoryId=${iCategoryId}&iSubCategoryId=${iSubCategoryId}&isFullResponse=${isFullResponse}`)
}

export function getEventTempDetails (id) {
  return axios.get(`/ot/admin/event-template-details/${id}/v1`)
}

export function getQuestionConfig() {
  return axios.get(`/ot/admin/event-template/question-configs/v1`)
}