import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, Col, FormGroup, Input, Modal, ModalBody, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import qs from 'query-string'

import editButton from '../../../assets/images/edit-pen-icon.svg'
import warningIcon from '../../../assets/images/error-warning.svg'

import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import Loading from '../../../components/Loading'
import SkeletonTable from '../../../components/SkeletonTable'
import PaginationComponent from '../../../components/PaginationComponent'

import { updateEventTemplate } from '../../../api/EventTemplate/mutation'

import { modalMessageFunc } from '../../../helpers/helper'

const EventTemplate = forwardRef((props) => {
  const { tempData, start, setStart, offset, setOffset, activePageNo, setPageNo, editLink } = props

  const location = useLocation()
  const obj = qs.parse(location?.search)
  const navigate = useNavigate()
  const paginationFlag = useRef(false)
  const queryClient = useQueryClient()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [index, setIndex] = useState(1)
  const [total, setTotal] = useState(0)
  const [listLength, setListLength] = useState('10 Rows')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [type, setType] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [modalWarning, setModalWarning] = useState(false)

  const toggleWarning = () => setModalWarning(!modalWarning)

  const adminPermission = useSelector(state => state?.auth)
  const previousProps = useRef({ start, offset }).current

  // update status mutation function for list data status update operation
  const { mutate: updateStatusMutation } = useMutation(
    (data) => { return updateEventTemplate(data.updateData, data.id) },
    {
      onSuccess: (response) => {
        setMessage(response?.data?.message)
        setModalMessage(true)
        setStatus(true)
        queryClient.invalidateQueries('getTemplateList')
      },
      onError: (error) => {
        console.error('Error updating Template:', error)
      }
    }
  )

  // useEffect to get data from api and set data to list and set pagination data to pagination component and set loading to false after data is fetched
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
    }
    navigate(location?.pathname, { replace: true })
    let page = 1
    let limit = offset
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setStart((page - 1) * offset)
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
    }
  }, [])

  // useEffect to set data to list and set pagination data to pagination component and set loading to false after data is fetched
  useEffect(() => {
    if (tempData) {
      if (tempData?.results) {
        const length = tempData?.results?.length
        const startFrom = ((activePageNo - 1) * offset) + 1
        const end = (startFrom - 1) + length
        setStartingNo(startFrom)
        setEndingNo(end)
      }
      setList(tempData?.results || [])
      setIndex(activePageNo)
      setTotal(tempData?.total || 0)
    }
    setLoading(false)
  }, [tempData])

  // useEffect to set message and status to alert component and set modal message to false after message is set
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // useEffect to set loading to true when start or offset is changed and set start and offset to previousProps for comparison
  useEffect(() => {
    if (previousProps.start !== start || previousProps.offset !== offset) {
      // setLoading(true)
    }
    previousProps.start = start
    previousProps.offset = offset
  }, [start, offset])

  // function to show warning message for activate/inactivate operation and set selectedData and type to state
  function warningWithConfirmMessage (data, eType) {
    setType(eType)
    setSelectedData(data)
    setModalWarning(true)
  }

  // update status from list data and close modal on confirm operation for activate/inactivate operation
  function onStatusUpdate () {
    const status = selectedData.eStatus === 'Y' ? 'N' : 'Y'
    const updatedData = {
      sName: selectedData?.sName,
      nDuration: selectedData?.nDuration/60000,
      sDescription: selectedData?.sDescription,
      sQuestion: selectedData?.sQuestion,
      eCategoryType: selectedData?.oCategory?.eCategoryType,
      iCategoryId: selectedData?.iCategoryId,
      iSubCategoryId: selectedData?.iSubCategoryId,
      oConfig: selectedData?.oConfig,
      eStatus: status
    }
    updateStatusMutation({ updateData: updatedData, id: selectedData?._id })
    toggleWarning()
  }


  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      {!loading && tempData?.results?.length === 0
        ? (<DataNotFound message='Event Template' obj={obj} />)
        : (
          <div className='table-represent'>
            <div className='table-responsive'>
              {loading && <Loading />}
              <table className='table'>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Duration(Minutes)</th>
                    <th>Outcome Paramater</th>
                    <th>Created Date & Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? (<SkeletonTable numberOfColumns={10} />)
                    : (
                      <Fragment>
                        {list && list?.length !== 0 && list?.map((data, i) => (
                          <tr key={i}>
                            <td>{(index - 1) * offset + (i + 1)}</td>
                            <td>
                              <FormGroup switch style={{ paddingLeft: '0px' }}>
                                <Input
                                  key={`${data?._id}`}
                                  checked={data?.eStatus === 'Y'}
                                  disabled={adminPermission?.RULE === 'R'}
                                  id={`${data?._id}`}
                                  name={`${data?._id}`}
                                  onClick={() => warningWithConfirmMessage(data, data?.eStatus === 'Y' ? 'Inactivate' : 'Activate')}
                                  type='switch'
                                />
                              </FormGroup>
                            </td>
                            <td>{data?.sName || '--'}</td>
                            <td>{data?.oCategory?.sName || '--'}</td>
                            <td>{data?.oSubCategory?.sName || '--'}</td>
                            <td>{data?.nDuration/60000 || '0'}</td>
                            <td>{data?.oConfig?.eOutComeParameter === 'L' ? 'Likes' : data?.oConfig?.eOutComeParameter === 'C' ? 'Comments' : data?.oConfig?.eOutComeParameter === 'P' ? 'Price' : data?.oConfig?.eOutComeParameter === 'V' ? 'Views' : '--'}</td>
                            <td>{data?.dCreatedAt ? moment(data?.dCreatedAt)?.format('lll') : '--'}</td>
                            <td>
                              <ul className='action-list mb-0 d-flex p-0'>
                                <li>
                                  <Button className='edit-btn-icon' color="link" tag={Link} to={`${editLink}/${data?._id}`}>
                                    <img alt="View" src={editButton} />
                                  </Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        )
      }

      <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
        <ModalBody className='text-center'>
          <img alt='check' className='info-icon' src={warningIcon} />
          <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
          <Row className='row-12'>
            <Col>
              <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={toggleWarning} type='submit'>Cancel</Button>
            </Col>
            <Col>
              <Button className='theme-btn danger-btn full-btn' onClick={onStatusUpdate} type='submit'>{`${type} It`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {list?.length !== 0 && (
        <PaginationComponent
          activePageNo={activePageNo}
          endingNo={endingNo}
          listLength={listLength}
          offset={offset}
          paginationFlag={paginationFlag}
          setListLength={setListLength}
          setLoading={setLoading}
          setOffset={setOffset}
          setPageNo={setPageNo}
          setStart={setStart}
          startingNo={startingNo}
          total={total}
        />
      )}
    </Fragment>
  )
})

EventTemplate.displayName = 'EventTemplate'
export default EventTemplate