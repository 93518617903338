import axios from '../../axios'

export const createEventTemplate = async (addEventTemp) => {
  const { sName, nDuration, sDescription, sQuestion, eCategoryType, eStatus, iCategoryId, iSubCategoryId, oConfig } = addEventTemp
  return await axios.post('/ot/admin/event-template/v1', {
    sName,
    nDuration,
    sDescription,
    sQuestion,
    eCategoryType,
    eStatus,
    iCategoryId,
    iSubCategoryId,
    oConfig
  })
}

export const updateEventTemplate = async (updateEventTemp, id) => {
  const { sName, nDuration, sDescription, sQuestion,  eCategoryType, eStatus, iCategoryId, iSubCategoryId, oConfig } = updateEventTemp
  return await axios.put(`/ot/admin/event-template/${id}/v1`, {
    sName,
    nDuration,
    sDescription,
    sQuestion,
    eCategoryType,
    eStatus,
    iCategoryId,
    iSubCategoryId,
    oConfig
  })
}