import { Crypt } from 'hybrid-crypto-js'
import store from '../store'
import { history } from '../App'
import moment from 'moment'


const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDUH3YJ9lSOPsof/8qyHKPG1kuA
QXNLEWE4bd+VLBgbEitOwm9+TLpzcnzweaiVfr9NIoaEydxP4ZlJF/h/7fhOuazS
QRld429/k+ZzyfmpDkGIPbgKOndPdy0AuWZoiEMXKQvSbtmbCN0isWlquW1vU7Fn
SJi4Dm1LbgpnL6FLgwIDAQAB
-----END PUBLIC KEY-----`

// Password Encryption
export function encryption(sPassword) {
  const crypt = new Crypt()
  const encrypted = crypt.encrypt(publicKey, sPassword)
  return encrypted.toString()
}
//Alert Class
export function alertClass(status, close) {
  if (status) {
    return `sucess-alert ${!close ? 'alert' : 'alert-close'}`
  }
  return `fail-alert ${!close ? 'alert' : 'alert-close'}`
}

//Check isNumber 
export function isNumber (value) {
  const numRex = /^[0-9\b]+$/
  if (numRex.test(value)) {
    return true
  }
  return false
}

//Modal Message
export function modalMessageFunc (modalMessage, setModalMessage, setClose) {
  if (modalMessage) {
    setTimeout(() => {
      setModalMessage(false)
      setClose(false)
    }, 3000)
    setTimeout(() => {
      setClose(true)
    }, 2500)
  }
}

//Verify length
export function verifyLength (value, length) {
  if (value?.length >= length) {
    return true
  }
  return false
}

//Check Space
export function withoutSpace (value) {
  const SpacelessRex = /\s/g
  if (SpacelessRex.test(value)) {
    return true
  }
  return false
}

//Verify Password
export function verifyPassword (value) {
  const passwordRex = /^[\S]{5,14}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

//Verify Email 
export function verifyEmail (value) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

// Catch function
export function catchFunc (type, error) {
  const errorConfig = error?.response?.data?.data
  const errorMessage = errorConfig && errorConfig?.length && errorConfig?.map((data) => {
    return data?.msg + ' of ' + data?.param
  })
  return { 
    type,
    payload: {
      resMessage: error?.response ? error.response?.data?.message || errorMessage?.toString() : 'Server is unavailable.',
      resStatus: false,
      type
    }
  }
}

//Success Response
export function successFunc (type, response) {
  return {
    type,
    payload: {
      resMessage: response.data.message,
      resStatus: true,
      type
    }
  }
}

// reducer
export function commonReducer (state, action) {
  return {
    ...state,
    resStatus: action.payload.resStatus,
    resMessage: action.payload.resMessage,
    type: action.payload.type
  }
}

//Un Authorized
export function unAuthorized () {
  localStorage.removeItem('Token')
  store.dispatch({
    type: 'TOKEN_LOGIN',
    payload: {
      token: null
    }
  })
  history.push('/auth/login')
}

//Key Labels
export const eKeyLabels = {
  P: 'Profile',
  D: 'Process Deposit',
  W: 'Process Withdraw',
  KYC: 'KYC',
  BD: 'Bank Details',
  SUB: 'Admin Management',
  AD: 'Deposit',
  AW: 'Withdraw',
  PC: 'Promo Code',
  L: 'contest',
  PB: 'contest Prize Breakup',
  M: 'Match',
  ML: 'Match contest',
  S: 'Settings',
  CR: 'Common Rules',
  CF: 'Complaints & Feedback',
  SLB: 'Series Leaderboard',
  MP: 'Match Player',
  LB: 'Leaderboard'
}

//Date from to date to
export function dateFromTOdateTO (dateRange) {
  const formattedDates = dateRange.map((dateString) => {
    const formattedDate = moment(dateString).format('MM/DD/YYYY')
    return formattedDate
  })

  return `${formattedDates[0]}-${formattedDates[1]}`
}

//Verify Special Character with space
export function verifySpecialCharacterWithSpaceInput (value) {
  const regex = /^[0-9a-zA-Z\s]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

//Verify Mobile Number
export function verifyMobileNumber (value) {
  const mobRex = /^[0-9]{10}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

//Verify Special Character
export function verifySpecialCharacter (value) {
  const regex = /^[0-9a-zA-Z]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

//Verify Length of User Name
export function verifyLengthUserName (value, length) {
  if (value?.length >= length && value.length <= 16) {
    return true
  }
  return false
}

//Verify URL
export function verifyUrl (value) {
  const urlRex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  if (urlRex.test(value)) {
    return true
  }
  return false
}

//Accepted Format
export function acceptFormat () {
  return '.png, .jpg, .jpeg'
}

//Check Is Float
export function isFloat (value) {
  const numRex = /^\d+(\.\d{1,2})?$/
  if (numRex.test(value)) {
    return true
  }
  return false
}

export function WithZero (value) {
  const regex = /^0+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function withInitialSpace (value) {
  const SpaceRegex = /^(.+)$/
  if (SpaceRegex.test(value)) {
    return true
  }
  return false
}

//Check IFSC Code
export function ifscCode (value) {
  const ifscRex = /^[A-Z]{4}0[A-Z0-9]{6}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}

//Check Pan Card
export function panCardNumber (value) {
  // const panRex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  const panRex =  /^[A-Z]{5}[0-9]{4}[A-Z]{1}?$/;
  if (!panRex.test(value?.toUpperCase())) {
    return true
  }
  return false
}

//Verify Aadhar Number
export function verifyAadhaarNumber (value) {
  const mobRex = /^[0-9]{12}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

//Verify PinCode
export function isPincode (value) {
  const pincodeRegEx = /^[1-9]{1}[0-9]{5}$/
  if (!pincodeRegEx.test(value)) {
    return true
  }
  return false
}

//Check is positive
export function isPositive (value) {
  if (value > 0) {
    return true
  }
  return false
}

//Type Options
export const typeOptions = [
  {
    settingsKeys:
    ['Deposit', 'Withdraw', 'UserDepositRateLimitTimeFrame', 'PCF', 'PUBC', 'PCS', 'TDS', 'UserWithdrawRateLimitTimeFrame', 'withdrawPermission'],
    options: [{ value: 'R', label: 'Range' }]
  },
  {
    settingsKeys:
    ['BonusExpireDays', 'CREATOR_BONUS', 'WITHDRAW_REJECT', 'FIX_DEPOSIT1', 'FIX_DEPOSIT2', 'FIX_DEPOSIT3', 'MEGA_CONTEST', 'DepositFees', 'WithdrawFees',
      'APPLICATIONS', 'APPLICATIONS_POPUP', 'SUBADMIN_BLOCK_MANAGE', 'DEPOSIT_TAX', 'WITHDRAW_TAX', 'CONTEST_JOIN_TAX'
    ],
    options: [{ value: 'F', label: 'Fixed' }]
  },
  {
    settingsKeys:
    ['FETCH_LINUP', 'USER_PROFILE_LEVEL', 'DELETED_USER_SHOULD_NOT_GET_BONUS', 'ALE', 'UserDepositRateLimit',
      'CURRENCY', 'STREAM_BUTTON', 'WinBifurcate', 'UserWithdrawRateLimit', 'MATCH_APILOGS', 'FD', 'LOGIN_STREAK', 'WINNING_BALANCE_MERCHANDISE'
    ],
    options: [
      { value: 'F', label: 'Fixed' },
      { value: 'R', label: 'Range' }
    ]
  }
]
export const currencySymbol = {
    Indian:'INR'
  }
// use For after show number point digit
export function fixDigit (value) {
  return Number.isInteger(value) ? Number(value) : Number(value).toFixed(2)
}
// date formate common Function
export function dateFormate (value) {
  return value ? moment(value).format('lll') : 'No Data Available'
}
export function formatCompactNumberForGraph (number, logo) {
  if (number < 1000) {
    return number
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(2) + 'K'
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2) + 'M'
  } else if (number >= 1000000000 && number < 1000000000000) {
    return (number / 1000000000).toFixed(2) + 'B'
  } else if (number >= 1000000000000 && number < 1000000000000000) {
    return (number / 1000000000000).toFixed(2) + 'T'
  } else return 0
}
// formate currency

export function formatCompactNumber (number) {
  if (number < 1000) {
    return number.toFixed(2)
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(2) + 'K'
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2) + 'M'
  } else if (number >= 1000000000 && number < 1000000000000) {
    return (number / 1000000000).toFixed(2) + 'B'
  } else if (number >= 1000000000000 && number < 1000000000000000) {
    return (number / 1000000000000).toFixed(2) + 'T'
  }
}

//Category Labels
export const CategoryLabels= {
  C: 'Cricket',
  S: 'Stock',
  CR: 'Crypto',
  IPO: 'IPO',
  GT: 'General Trading',
  N: 'News',
  YT: 'YouTube'
}

export const getYouTubeVideoId = (url) => {
  const regex = /[?&]v=([a-zA-Z0-9_-]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

function getOrdinalSuffix(i) {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export function formatDate(dateString, format = '') {
  if (!dateString) return null
  const date = new Date(dateString)
  const formatOptions = {
    YYYY: date.getFullYear(), // 2021
    YY: String(date.getFullYear()).slice(-2), // 21
    MMMM: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date), // January
    MMM: new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date), // Jan
    EEE: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()], // Mon
    MM: String(date.getMonth() + 1).padStart(2, '0'), // 01
    M: date.getMonth() + 1, // 1
    DD: String(date.getDate()).padStart(2, '0'), // 01
    D: date.getDate(), // 1
    DO: getOrdinalSuffix(date.getDate()), // 1st
    HH: String(date.getHours()).padStart(2, '0'), // 15
    hh: String(date.getHours() % 12 || 12).padStart(2, '0'), // 07 or 12
    mm: String(date.getMinutes()).padStart(2, '0'), // 01
    ss: String(date.getSeconds()).padStart(2, '0'), // 01
    A: date.getHours() >= 12 ? 'PM' : 'AM'
  }
  return format.replace(/YYYY|YY|MMMM|MMM|EEE|MM|M|DD|DO|D|HH|hh|mm|ss|A/g, (match) => formatOptions[match])
}