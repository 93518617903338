import axios from '../../axios'
const getEventsList = async (start, limit,status,sliderSubCategory) => {
  let url = `/ot/admin/event/list/v1?start=${start}&limit=${limit}`
  if(status){
    url += `&eStatus=${status}`
  }
  if(sliderSubCategory && sliderSubCategory?.value){
    url += `&iSubCategoryId=${sliderSubCategory?.value}`
  }
  return await axios.get(url)
}

export default getEventsList

export const getYtVideoDetails = async (id) => {
  if (id) {
    let url = `/ot/admin/video-details/v1?id=${id}`
    return await axios.get(url)
  } return null
}

export const getCurrentPriceDetails = async (sSymbol, eExchange, eProvider) => {
  if (sSymbol && eExchange) {
    let url = `/ot/admin/event/stock/current-price/v1?sSymbol=${sSymbol}&eExchange=${eExchange}`
    if (eProvider) url += `&eProvider=${eProvider}`
    return await axios.get(url)
  } return null
}

export const getYtQuesFormat = async () => {
  let url = `/ot/admin/question/v1`
  return await axios.get(url)
}