import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import Select from 'react-select'

import RequiredField from '../../../../components/RequiredField'
import AlertMessage from '../../../../components/AlertMessage'

const AddUpdateEventTemplate = forwardRef((props, ref) => {
  const { id, adminPermission, categoryList, message, setMessage, close, modalMessage, status,
    eventTempDetails, subCategoryData, subCategoryLoading, createMutation, updateMutation,
    fetchNextPage, hasNextPage, queConfig, setEventCategoryId, eventCategoryId
  } = props

  const [eventTempData, setEventTempData] = useState({
    iCategoryId: '',
    categoryName: '',
    sName: '',
    nDuration: 0,
    sDescription: '',
    sQuestion: '',
    eStatus: 'N',
    iSubCategoryId: '',
    subCategoryName: '',
    eCategoryType: '',
    oConfig: {
      eOutComeParameter: '',
      eComparisonType: '',
      eThresholdChangeType: '',
      nThreshold: 0,
      nMinThreshold: 0,
      nMaxThreshold: 0
    }
  })

  const [error, setError] = useState({
    iCategoryId: '',
    iSubCategoryId: '',
    sName: '',
    nDuration: '',
    sDescription: '',
    sQuestion: '',
    eStatus: '',
    eCategoryType: '',
    eOutComeParameter: '',
    eComparisonType: '',
    eThresholdChangeType: '',
    nThreshold: '',
    nMinThreshold: '',
    nMaxThreshold: ''
  })
  const [subCategoryOptions, setSubCategoryOptions] = useState([])


  useEffect(() => {
    const tempSubCategoryOptions = subCategoryData
      ?.filter(subCat =>
        subCat?.bShouldCreateAutoEvent === true &&
        (eventCategoryId ? subCat?.iCategoryId === eventCategoryId : true)
      )
      ?.map((subCat) => ({
        value: subCat?._id,
        label: subCat?.sName,
      })) || []

    setSubCategoryOptions(tempSubCategoryOptions)
  }, [subCategoryData])


  // Populate form data when event template details are loaded
  useEffect(() => {
    if (eventTempDetails) {
      const category = categoryList?.find(
        (cat) => cat?._id === eventTempDetails?.iCategoryId
      )
      const subCategory = subCategoryData?.find(
        (subCat) => subCat?._id === eventTempDetails?.iSubCategoryId
      )
      setEventCategoryId(eventTempDetails?.iCategoryId)
      setEventTempData({
        iCategoryId: eventTempDetails?.iCategoryId || '',
        sName: eventTempDetails?.sName || '',
        categoryName: category?.sName || '',
        nDuration: eventTempDetails?.nDuration ? Math.round(eventTempDetails.nDuration / 60000) : 0,
        sDescription: eventTempDetails?.sDescription || '',
        sQuestion: eventTempDetails?.sQuestion || '',
        eStatus: eventTempDetails?.eStatus || 'N',
        iSubCategoryId: eventTempDetails?.iSubCategoryId || '',
        subCategoryName: subCategory?.sName || '',
        eCategoryType: eventTempDetails?.oCategory?.eCategoryType || eventTempDetails?.oSubCategory?.eCategoryType,
        oConfig: {
          eOutComeParameter: eventTempDetails?.oConfig?.eOutComeParameter || '',
          eComparisonType: eventTempDetails?.oConfig?.eComparisonType || '',
          eThresholdChangeType: eventTempDetails?.oConfig?.eThresholdChangeType || '',
          nThreshold: eventTempDetails?.oConfig?.nThreshold || 0,
          nMinThreshold: eventTempDetails?.oConfig?.nMinThreshold || 0,
          nMaxThreshold: eventTempDetails?.oConfig?.nMaxThreshold || 0,
        }
      })
    }
  }, [eventTempDetails])

  const handleChange = (event, type) => {
    const { value } = event?.target
    let newError = { ...error }
    switch (type) {
      case 'iCategoryId':
        setEventTempData({ ...eventTempData, iCategoryId: value })
        newError.iCategoryId = value ? '' : 'Required Field'
        break
      case 'iSubCategoryId':
        setEventTempData({ ...eventTempData, iSubCategoryId: value })
        newError.iSubCategoryId = value ? '' : 'Required Field'
      case 'sName':
        setEventTempData({ ...eventTempData, sName: value })
        newError.sName = value ? '' : 'Required Field'
        break
      case 'nDuration':
        setEventTempData({ ...eventTempData, nDuration: value })
        newError.nDuration = value ? '' : 'Required Field'
        break
      case 'sDescription':
        setEventTempData({ ...eventTempData, sDescription: value })
        newError.sDescription = value ? '' : 'Required Field'
        break
      case 'sQuestion':
        setEventTempData({ ...eventTempData, sQuestion: value })
        newError.sQuestion = value ? '' : 'Required Field'
        break
      case 'eStatus':
        setEventTempData({ ...eventTempData, eStatus: value })
        newError.eStatus = value ? '' : 'Required Field'
        break
      case 'eOutComeParameter':
        setEventTempData({ ...eventTempData, oConfig: { ...eventTempData.oConfig, eOutComeParameter: value } })
        newError.eOutComeParameter = value ? '' : 'Required Field'
        break
      case 'eComparisonType':
        setEventTempData({ ...eventTempData, oConfig: { ...eventTempData.oConfig, eComparisonType: value } })
        newError.eComparisonType = value ? '' : 'Required Field'
        break
      case 'eThresholdChangeType':
        setEventTempData({
          ...eventTempData,
          oConfig: {
            ...eventTempData.oConfig,
            eThresholdChangeType: value,
            // Reset other threshold fields when type changes
            nThreshold: 0,
            nMinThreshold: 0,
            nMaxThreshold: 0
          }
        })
        // Clear previous threshold-related errors
        newError.nThreshold = ''
        newError.nMinThreshold = ''
        newError.nMaxThreshold = ''
        break
      case 'nThreshold':
        // Validate threshold for Fixed and Percentage types
        setEventTempData({
          ...eventTempData,
          oConfig: {
            ...eventTempData.oConfig,
            nThreshold: value
          }
        })
        // Only validate if Threshold Change Type is Fixed or Percentage
        if (eventTempData.oConfig.eThresholdChangeType === 'F' ||
          eventTempData.oConfig.eThresholdChangeType === 'P') {
          newError.nThreshold = value ? '' : 'Required Field'
        }
        break
      case 'nMinThreshold':
        setEventTempData({
          ...eventTempData,
          oConfig: {
            ...eventTempData.oConfig,
            nMinThreshold: value
          }
        })
        // Validate only for Range type
        if (eventTempData.oConfig.eThresholdChangeType === 'R') {
          newError.nMinThreshold = value ? '' : 'Minimum Threshold is required'

          // Additional validation to ensure min is less than max
          if (value && eventTempData.oConfig.nMaxThreshold) {
            const min = parseFloat(value)
            const max = parseFloat(eventTempData.oConfig.nMaxThreshold)
            if (min >= max) {
              newError.nMinThreshold = 'Minimum must be less than Maximum'
            }
          }
        }
        break
      case 'nMaxThreshold':
        setEventTempData({
          ...eventTempData,
          oConfig: {
            ...eventTempData.oConfig,
            nMaxThreshold: value
          }
        })
        // Validate only for Range type
        if (eventTempData?.oConfig?.eThresholdChangeType === 'R') {
          newError.nMaxThreshold = value ? '' : 'Maximum Threshold is required'

          // Additional validation to ensure max is greater than min
          if (value && eventTempData.oConfig.nMinThreshold) {
            const max = parseFloat(value)
            const min = parseFloat(eventTempData?.oConfig?.nMinThreshold)
            if (max <= min) {
              newError.nMaxThreshold = 'Maximum must be greater than Minimum'
            }
          }
        }
        break
      default:
        break
    }
    setError(newError)
  }

  // handle SubCategory change
  const handleSelectChange = (selectedOption, field) => {
    if (field === 'iSubCategoryId') {
      const subCategory = subCategoryData?.find(
        (subCat) => subCat?._id === selectedOption?.value
      )
      setEventTempData((prev) => ({
        ...prev,
        iSubCategoryId: selectedOption?.value || '',
        subCategoryName: subCategory?.sName || '',
      }))
    }
  }

  // Handle category change 
  const handleCategoryTypeChange = (value) => {
    const category = categoryList?.find(
      (cat) => cat?._id === value
    )
    setEventTempData((prev) => ({
      ...prev,
      iCategoryId: value || '',
      eCategoryType: category?.eCategoryType || '',
      categoryName: category?.sName || '',
    }))
    setEventCategoryId(value)
    setError((prev) => ({
      ...prev,
      iCategoryId: value ? '' : 'Required Field',
      eCategoryType: category?.eCategoryType ? '' : 'Required Field',
    }))
  }

  // validate question field based on category type and required fields
  const validateQuestionField = () => {
    const requiredFields = queConfig?.[eventTempData?.eCategoryType] || []
    if (requiredFields?.length === 0) {
      setError((prev) => ({ ...prev, sQuestion: '' }))
      return true
    }
    const missingFields = requiredFields?.every(variable => eventTempData?.sQuestion?.includes(variable))
    
    // If there are missing fields, set error
    if (missingFields === false) {
      setError((prev) => ({
        ...prev,
        sQuestion: `Required fields missing: ${requiredFields.join(', ')}`,
      }))
      return false
    }

    // Clear any previous errors
    setError((prev) => ({ ...prev, sQuestion: '' }))
    return true
  }

  // Handle form submission and validation checks before submission
  const onSubmit = () => {
    const isQuestionValid = validateQuestionField()

    if (!isQuestionValid) {
      setMessage('Please fill all required fields for the question.')
      return
    }

    // Prepare a new error object
    let newError = { ...error }

    // Reset previous threshold-related errors
    newError.nThreshold = ''
    newError.nMinThreshold = ''
    newError.nMaxThreshold = ''

    // Check basic required fields
    const requiredFields = [
      'iCategoryId', 'iSubCategoryId', 'sName', 'sQuestion',
      'nDuration', 'sDescription', 'oConfig.eOutComeParameter',
      'oConfig.eComparisonType', 'oConfig.eThresholdChangeType'
    ]

    const missingFields = requiredFields.filter(field => {
      const value = field.includes('.')
        ? eventTempData?.oConfig[field.split('.')[1]]
        : eventTempData[field]
      return !value
    })

    const thresholdChangeType = eventTempData?.oConfig?.eThresholdChangeType

    if (thresholdChangeType === 'R') {
      // Range validation
      if (!eventTempData?.oConfig?.nMinThreshold) {
        newError.nMinThreshold = 'Minimum Threshold is required'
        missingFields.push('nMinThreshold')
      }
      if (!eventTempData?.oConfig?.nMaxThreshold) {
        newError.nMaxThreshold = 'Maximum Threshold is required'
        missingFields.push('nMaxThreshold')
      }

      // Check min < max
      if (eventTempData?.oConfig?.nMinThreshold && eventTempData?.oConfig?.nMaxThreshold) {
        const min = parseFloat(eventTempData.oConfig.nMinThreshold)
        const max = parseFloat(eventTempData.oConfig.nMaxThreshold)
        if (min >= max) {
          newError.nMinThreshold = 'Minimum must be less than Maximum'
          newError.nMaxThreshold = 'Maximum must be greater than Minimum'
        }
      }
    } else if (thresholdChangeType === 'F' || thresholdChangeType === 'P') {
      // Fixed or Percentage validation
      if (!eventTempData?.oConfig?.nThreshold) {
        newError.nThreshold = 'Threshold is required'
        missingFields.push('nThreshold')
      }

      // Clear min and max threshold errors for F and P types
      newError.nMinThreshold = ''
      newError.nMaxThreshold = ''
    }
    if (eventTempData?.iCategoryId === '') {
      newError.iCategoryId = 'Required field'
      missingFields.push('iCategoryId')
    }

    if (eventTempData?.iSubCategoryId === '') {
      newError.iSubCategoryId = 'Required field'
      missingFields.push('iSubCategoryId')
    }
    // Validate Outcome Parameter based on Category Type (CR, S, C, L, V)
    if (eventTempData?.eCategoryType === 'CR' || eventTempData?.eCategoryType === 'S') {
      if (!eventTempData?.oConfig?.eOutComeParameter) {
        newError.eOutComeParameter = 'Required field'
        missingFields.push('eOutComeParameter')
      }
    } else {
      if (!eventTempData?.oConfig?.eOutComeParameter) {
        newError.eOutComeParameter = 'Required field'
        missingFields.push('eOutComeParameter')
      }
    }

    if (eventTempData?.sName === '') {
      newError.sName = 'Required field'
      missingFields.push('sName')
    }
    if (eventTempData?.nDuration === '' || eventTempData?.nDuration === 0) {
      newError.nDuration = 'Required field'
      missingFields.push('nDuration')
    }

    if (eventTempData?.sDescription === '') {
      newError.sDescription = 'Required field'
      missingFields.push('sDescription')
    }

    if (eventTempData?.sQuestion === '') {
      newError.sQuestion = 'Required field'
      missingFields.push('sQuestion')
    }
    if (eventTempData?.oConfig?.eComparisonType === '') {
      newError.eComparisonType = 'Required field'
      missingFields.push('eComparisonType')
    }

    // Set updated errors
    setError(newError)

    // Check if there are any missing fields
    if (missingFields?.length > 0) {
      setMessage(`Please fill all required fields: ${missingFields.join(', ')}`)
      return
    }

    // Prepare submission data
    const submissionData = {
      iCategoryId: eventTempData?.iCategoryId,
      iSubCategoryId: eventTempData?.iSubCategoryId,
      sName: eventTempData?.sName,
      sQuestion: eventTempData?.sQuestion,
      nDuration: eventTempData?.nDuration,
      sDescription: eventTempData?.sDescription,
      eCategoryType: eventTempData?.eCategoryType,
      eStatus: eventTempData?.eStatus || 'N',
      oConfig: {
        eOutComeParameter: eventTempData?.oConfig?.eOutComeParameter,
        eComparisonType: eventTempData?.oConfig?.eComparisonType,
        eThresholdChangeType: eventTempData.oConfig?.eThresholdChangeType,
        nThreshold: eventTempData?.oConfig?.nThreshold || 0,
        nMinThreshold: eventTempData?.oConfig?.nMinThreshold,
        nMaxThreshold: eventTempData?.oConfig?.nMaxThreshold
      }
    }

    // Submit based on id
    if (id) {
      updateMutation(submissionData)
    } else {
      createMutation(submissionData)
    }
  }

  useImperativeHandle(ref, () => ({
    onSubmit,
  }))

  return (
    <main className='main-content'>
      <AlertMessage close={close} message={message} modalMessage={modalMessage} status={status} />
      <section className='common-form-block'>
        <Form>
          <Row>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='category'>
                  Category
                  <RequiredField />
                </Label>
                <Input
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name='iCategoryId'
                  onChange={(e) => handleCategoryTypeChange(e?.target?.value)}
                  type='select'
                  value={eventTempData?.iCategoryId}
                >
                  <option value=''>Select Category</option>
                  {categoryList?.filter((category) => category?.bIsAutomationAllowed === true)?.map((category) => (
                    <option key={category?._id} value={category?._id}>
                      {category?.sName}
                    </option>
                  ))}
                </Input>
                {error?.iCategoryId && <p className='error-text'>{error?.iCategoryId}</p>}
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group mt-2'>
                <Label className='edit-label-setting' for='subCat'>
                  Sub Category
                  <RequiredField />
                </Label>
                <Select
                  className='season-select'
                  options={subCategoryOptions}
                  isDisabled={adminPermission?.OT_EVENT === 'R'}
                  onMenuScrollToBottom={() => {
                    if (hasNextPage && !subCategoryLoading) {
                      fetchNextPage()
                    }
                  }}
                  placeholder='Select Sub Category'
                  value={subCategoryOptions.find(
                    (opt) => opt?.value === eventTempData?.iSubCategoryId
                  )}
                  onChange={(option) =>
                    handleSelectChange(option, 'iSubCategoryId')
                  }
                />
                {error?.iSubCategoryId && <p className='error-text'>{error?.iSubCategoryId}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="tempName"> Name <RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="name"
                  onChange={event => handleChange(event, 'sName')}
                  placeholder="Enter Template Name"
                  value={eventTempData?.sName}
                />
                {error?.sName && <p className='error-text'>{error?.sName}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="duration"> Duration(Minutes) <RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="nDuration"
                  onChange={event => handleChange(event, 'nDuration')}
                  placeholder="Enter Template Name"
                  value={eventTempData?.nDuration}
                  type='number'
                />
                {error?.nDuration && <p className='error-text'>{error?.nDuration}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {(eventTempData?.eCategoryType === 'CR' || eventTempData?.eCategoryType === 'S') && (
              <Col md={12} xl={6} className='mt-3'>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="outcomeParams">Outcome Parameter<RequiredField /></Label>
                  <Input
                    autoComplete='off'
                    className='form-control'
                    disabled={adminPermission?.OT_EVENT === 'R'}
                    name="eOutComeParameter"
                    onChange={(event) => handleChange(event, 'eOutComeParameter')}
                    type='select'
                    value={eventTempData?.oConfig?.eOutComeParameter}
                  >
                    <option value=''>Select Outcome Parameter</option>
                    <option value='P'>Price</option>
                  </Input>
                  {error?.eOutComeParameter && <p className='error-text'>{error?.eOutComeParameter}</p>}
                </FormGroup>
              </Col>
            )}

            {(eventTempData?.eCategoryType !== 'CR' && eventTempData?.eCategoryType !== 'S') && (
              <Col md={12} xl={6} className='mt-3'>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="outcomeParams">Outcome Parameter<RequiredField /></Label>
                  <Input
                    autoComplete='off'
                    className='form-control'
                    disabled={adminPermission?.OT_EVENT === 'R'}
                    name="eOutComeParameter"
                    onChange={(event) => handleChange(event, 'eOutComeParameter')}
                    type='select'
                    value={eventTempData?.oConfig?.eOutComeParameter}
                  >
                    <option value=''>Select Outcome Parameter</option>
                    <option value='C'>Comments</option>
                    <option value='L'>Likes</option>
                    <option value='V'>Views</option>
                  </Input>
                  {error?.eOutComeParameter && <p className='error-text'>{error?.eOutComeParameter}</p>}
                </FormGroup>
              </Col>
            )}

            <Col md={12} xl={6} className='mt-3'>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="compType">Comparison Type<RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="compType"
                  onChange={event => handleChange(event, 'eComparisonType')}
                  type='select'
                  value={eventTempData?.oConfig?.eComparisonType}
                >
                  <option value=''>Select Comparison Type</option>
                  <option value='GT'>Greater Than</option>
                  <option value='LT'>Less Than</option>
                </Input>
                {error?.eComparisonType && <p className='error-text'>{error?.eComparisonType}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="thraseChangeType">Threshold Change Type<RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="thraseChangeType"
                  onChange={event => handleChange(event, 'eThresholdChangeType')}
                  type='select'
                  value={eventTempData?.oConfig?.eThresholdChangeType}
                >
                  <option value=''>Select Threshold Change Type</option>
                  <option value='F'>Fixed</option>
                  <option value='R'>Range</option>
                  <option value='P'>Percentage</option>
                </Input>
                {error?.eThresholdChangeType && <p className='error-text'>{error?.eThresholdChangeType}</p>}
              </FormGroup>
            </Col>
          </Row>
          {(eventTempData?.oConfig?.eThresholdChangeType === 'R') && (
            <>
              <Row>
                <Col md={12} xl={6} className='mt-3'>
                  <FormGroup className='form-group'>
                    <Label className='edit-label-setting' for="nMin"> Miniumum Threshold<RequiredField /></Label>
                    <Input
                      autoComplete='off'
                      className='form-control'
                      disabled={adminPermission?.OT_EVENT === 'R'}
                      name="nMin"
                      onChange={event => handleChange(event, 'nMinThreshold')}
                      placeholder="Enter Min Threshold"
                      type='number'
                      value={eventTempData?.oConfig?.nMinThreshold || ''}
                    />
                    {error?.nMinThreshold && <p className='error-text'>{error?.nMinThreshold}</p>}
                  </FormGroup>
                </Col>
                <Col md={12} xl={6} className='mt-3'>
                  <FormGroup className='form-group'>
                    <Label className='edit-label-setting' for="nMax"> Maximum Threshold<RequiredField /></Label>
                    <Input
                      autoComplete='off'
                      className='form-control'
                      disabled={adminPermission?.OT_EVENT === 'R'}
                      name="nMax"
                      onChange={event => handleChange(event, 'nMaxThreshold')}
                      placeholder="Enter Max Threshold"
                      type='number'
                      value={eventTempData?.oConfig?.nMaxThreshold || ''}
                    />
                    {error?.nMaxThreshold && <p className='error-text'>{error?.nMaxThreshold}</p>}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {(eventTempData?.oConfig?.eThresholdChangeType === 'P' || eventTempData?.oConfig?.eThresholdChangeType === 'F') && (
            <Row>
              <Col md={12} xl={12} className='mt-3'>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="theshold">Threshold<RequiredField /></Label>
                  <Input
                    autoComplete='off'
                    className='form-control'
                    disabled={adminPermission?.OT_EVENT === 'R'}
                    name="theshold"
                    onChange={event => handleChange(event, 'nThreshold')}
                    placeholder="Enter Threshold"
                    type='number'
                    value={eventTempData?.oConfig?.nThreshold}
                  />
                  {error?.nThreshold && <p className='error-text'>{error?.nThreshold}</p>}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="sDesc"> Description <RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="sDesc"
                  onChange={event => handleChange(event, 'sDescription')}
                  placeholder="Enter Template Name"
                  value={eventTempData?.sDescription}
                  type='textarea'
                />
                {error?.sDescription && <p className='error-text'>{error?.sDescription}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="sDesc"> Question <RequiredField /></Label>
                <Input
                  autoComplete='off'
                  className='form-control'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="Question"
                  onChange={event => handleChange(event, 'sQuestion')}
                  placeholder="Enter Template Name"
                  type='textarea'
                  value={eventTempData?.sQuestion}
                />
                {error?.sQuestion && <p className='error-text'>{error?.sQuestion}</p>}
              </FormGroup>
            </Col>
          </Row>
          <Row className='p-3 mt-2' >
            <div className='radio-button-div'>
              <Col md={12} xl={12}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting lable-league' for="ActiveOffer">Status</Label>
                  <div className="d-flex inline-input mt-2">
                    <div className='custom-radio custom-control'>
                      <Input
                        checked={eventTempData?.eStatus === 'Y'}
                        disabled={adminPermission?.RULE === 'R'}
                        id="bannerRadio1"
                        label="Active"
                        name="bannerRadio"
                        onChange={event => handleChange(event, 'eStatus')}
                        type="radio"
                        value="Y"
                        className='custom-control-input me-2'
                      />
                      <Label>Active</Label>
                    </div>
                    <div className='custom-radio custom-control'>
                      <Input
                        checked={eventTempData?.eStatus !== 'Y'}
                        disabled={adminPermission?.RULE === 'R'}
                        id="bannerRadio2"
                        label="In Active"
                        name="bannerRadio"
                        onClick={event => handleChange(event, 'eStatus')}
                        type="radio"
                        value="N"
                        className='custom-control-input me-2'
                      />
                      <Label>In Active</Label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </div>
          </Row>
        </Form>
      </section>
    </main>
  )
})

AddUpdateEventTemplate.displayName = 'AddUpdateEventTemplate'
export default AddUpdateEventTemplate
