import axios from '../../axios'
const getCategoryList = async (start, limit, search, status) => {
  let url = `/ot/admin/category/list/v1?start=${start}&limit=${limit}&search=${search}`
  if(status){
    url += `&eStatus=${status}`
  }
  return await axios.get(url)
}

export default getCategoryList
