import React, { Fragment, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import EventListMainHeader from '../../Component/EventListMainHeader'
import AddUpdateEventTemplate from './AddUpdateEventTemplate'

import getCategoryList from '../../../../api/category/getCategoryList'
import getSubCategoryList from '../../../../api/category/getSubCategoryList'
import { getEventTempDetails, getQuestionConfig } from '../../../../api/EventTemplate/query'
import { createEventTemplate, updateEventTemplate } from '../../../../api/EventTemplate/mutation'

function AddUpdateEventTmep (props) {
  const { id } = useParams()
  const content = useRef()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [eventCategoryId, setEventCategoryId] = useState('')
  // const [bShouldCreateAutoEvent, setBShouldCreateAutoEvent] = useState(true)

  function onSubmit () {
    content?.current?.onSubmit()
  }

  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)

  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })

  const { data: eventTempDetails, isLoading: eventDetailsLoading } = useQuery({
    queryKey: ['getEventTempDetails', id],
    queryFn: () => getEventTempDetails(id),
    enabled: !!id,
    select: (response) => response?.data?.data,
  })

  const { data: queConfig } = useQuery({
    queryKey: 'getQuestionConfig',
    queryFn: () => getQuestionConfig(),
    select: (res) => res?.data?.data,
  })

  const { data: subCategoryData, isFetching: subCategoryLoading, fetchNextPage, hasNextPage, } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10, eventCategoryId, 'Y'],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10, eventCategoryId, 'Y'),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages?.flatMap((page) => page?.data?.data?.results)?.length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap((page) => page?.data?.data?.results) || [],
    keepPreviousData: true,
  })

  const { mutate: createMutation } = useMutation(createEventTemplate, {
    onSuccess: (response) => {
      setMessage(response?.data?.message)
      setModalMessage(true)
      setStatus(true)
      queryClient.invalidateQueries('getTemplateList')
      navigate('/events/event-template', { state: { message: response?.data?.message } })
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message)
      setModalMessage(true)
      setStatus(false)
    }
  })

  const { mutate: updateMutation } = useMutation(
    (data) => updateEventTemplate(data, id), {
    onSuccess: (response) => {
      queryClient.invalidateQueries('getTemplateList')
      navigate('/events/event-template', { state: { message: response?.data?.message } })
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message)
      setModalMessage(true)
      setStatus(false)
    }
  })

  return (
    <Fragment>
      <EventListMainHeader
        AddUpdateEventTemplate
        heading={id ? 'Update Event Template' : 'Add Event Template'}
        button={id ? 'Save Changes' : 'Add Event Template'}
        onSubmit={onSubmit}
        Auth={Auth}
        adminPermission={adminPermission}
      />
      <div className='without-pagination'>
        <AddUpdateEventTemplate
          {...props}
          id={id}
          Auth={Auth}
          adminPermission={adminPermission}
          categoryList={categoryList}
          ref={content}
          message={message}
          status={status}
          close={close}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
          setMessage={setMessage}
          setStatus={setStatus}
          setClose={setClose}
          eventTempDetails={eventTempDetails}
          subCategoryData={subCategoryData}
          eventDetailsLoading={eventDetailsLoading}
          subCategoryLoading={subCategoryLoading}
          createMutation={createMutation}
          updateMutation={updateMutation}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          queConfig={queConfig}
          eventCategoryId={eventCategoryId}
          setEventCategoryId={setEventCategoryId}
          // bShouldCreateAutoEvent={bShouldCreateAutoEvent}
          // setBShouldCreateAutoEvent={setBShouldCreateAutoEvent}
        />
      </div>
    </Fragment>
  )
}

export default AddUpdateEventTmep