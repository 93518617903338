import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useQueryState } from 'react-router-use-location-state'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import debounce from 'lodash/debounce'

import EventListMainHeader from '../Component/EventListMainHeader'
import EventListHeader from '../Component/EventListHeader'
import EventTemplate from './EventTemplate'

import { getEventTemplates } from '../../../api/EventTemplate/query'
import getCategoryList from '../../../api/category/getCategoryList'
import getSubCategoryList from '../../../api/category/getSubCategoryList'

function EventTempIndex (props) {
  const content = useRef()
  const location = useLocation()

  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useQueryState('search', '')
  const [initialFlag, setInitialFlag] = useState(false)
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [eStatus, setEstatus] = useQueryState('eStatus', '')
  const [iCategoryId, setCategoryId] = useQueryState('iCategoryId', '')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [iSubCategoryId, setSubCategoryId] = useQueryState('iSubCategoryId', '')
  
  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
      setSearch(obj?.search)
    }
    if (obj?.estatus) {
      setEstatus(obj?.estatus)
    }
    if(obj?.iCategoryId){
      setCategoryId(obj?.iCategoryId)
    }
    if(obj?.iSubCategoryId){
      setSubCategoryId(obj?.iSubCategoryId)
    }
  }, [])

  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })
  const { data: subCategoryData, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 50, '', 'Y'],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 50, '', 'Y'),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page.data.data.results).length;
      return nextPage < total ? nextPage : undefined
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  })

  // ueQuery to fetch the event templates data 
  const { data: tempData, isLoading, refetch } = useQuery({
    queryKey: ['getTemplateList', start, offset, search, eStatus, iCategoryId, iSubCategoryId],
    queryFn: () => getEventTemplates(start, offset, search, eStatus, iCategoryId, iSubCategoryId),
    select: (response) => response?.data?.data
  })

  // Create a debounced function to update search query parameter
  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setSearch(value)
      setInitialFlag(true)
      setStart(0)
      setPageNo(activePageNo)
    }, 1000),
    []
  )

  // Cleanup debounce on component unmount
  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel()
    }
  }, [])

  // Handle change in search input field and update search query parameter
  function handleSearch (e) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    } else {
      setSearchText(e?.target?.value)
      debouncedSetSearch(e?.target?.value) // Debounced update of search parameter
    }
  }

  function onFiltering (selectedOption, type) {
    if (type === 'eStatus') {
      setEstatus(selectedOption?.value || '')
      setStart(0)
      setPageNo(1)
    } else if (type === 'category') {
      setSelectedCategory(selectedOption)
      setCategoryId(selectedOption?.value || '')
      setStart(0)
      setPageNo(1)
    }
  }

  return (
    <>
      <main className='main-content'>
        <section className='management-section common-box'>
          <EventListMainHeader
            eventTemplate
            heading='Event Templates'
            list={tempData?.results}
          />
          <div className={tempData?.results?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <EventListHeader
              eventTemplate
              search={searchText}
              handleSearch={handleSearch}
              onFiltering={onFiltering}
              categoryList={categoryList}
              iCategoryId={iCategoryId}
              subCategoryData={subCategoryData}
              selectedCategory={selectedCategory}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              setSubCategoryId={setSubCategoryId}
              buttonText="Add Event Template"
              setUrl="/events/event-template/add"
            />
            <EventTemplate
              {...props}
              ref={content}
              flag={initialFlag}
              tempData={tempData}
              isLoading={isLoading}
              refetch={refetch}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
              search={searchText}
              setSearch={setSearch}
              activePageNo={activePageNo}
              setPageNo={setPageNo}
              editLink='/events/event-template/update'
            />
          </div>
        </section>
      </main>
    </>
  )
}

export default EventTempIndex